.form-switch {
  --switch-off-bg:#657099;
  --switch-on-bg:#3B58E7;
  --switch-label-color:#B9C7D6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    font-weight: 600;
    font-size: 12px;
    color: var(--switch-label-color);
  }

  i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 30px;
    height: 14px;
    background-color: var(--switch-off-bg);
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 14px;
      height: 14px;
      background-color: var(--switch-off-bg);
      border-radius: 11px;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 14px;
      height: 14px;
      background-color: #fff;
      border-radius: 11px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:active {
    i::after {
      width: 14px;
      transform: translate3d(0, 0, 0);
    }

    input:checked + i::after {
      transform: translate3d(16px, 0, 0);
    }
  }

  input {
    display: none;

    &:checked + i {
      background-color: #3B58E7;

      &::before {
        transform: translate3d(16px, 0, 0) scale3d(0, 0, 0);
      }

      &::after {
        transform: translate3d(16px, 0, 0);
      }
    }
  }
}
