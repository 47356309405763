.wheel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;

    &.bg-wheel {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &.banner-wheel {
      position: absolute;
      bottom: 0;
    }

    &.win-sector-wheel {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 18px;
    }
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .wheel-sectors {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: 300px;

    .wheel-sector {
      -webkit-transform: perspective(1000px) rotateY(40deg);
      transform: perspective(112px) rotateY(37deg);
      height: 35px;
      width: 112px;
      background-color: red;
      position: relative;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      padding: 0 5px;

      &:nth-child(1) {
        left: 15px;
      }
    }
  }
}
.spin-item {
  width: 150px;
  padding-right: 47px;
  padding-left: 13px;
  top: calc(50% - 12px);
  left: 0;
  transform-origin: right;
  position: absolute;
  display: flex;
  align-items: center;

  .spin-item-inner {
    transform: rotate(180deg);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    width: 100%;

    img {
      width: 15px;
    }
    span {
      font-size: 12px;
    }
  }
}

.spin-item span {
    font-size: 9px;
    color: #fff;
}
