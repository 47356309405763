.opened-chat {
  padding-right: 382px;
}
chat {
  display: block;
  position: fixed;
  top: 69px;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 382px;
  

  .blue-shadow {
    position: absolute;
    filter: blur(100px);
    right: 0;
    top: 600px;
    z-index: -1;
  }

  .chat {
    --chat-bg:#0E121D;
    --chat-title-color:#ffffff;
    --chat-arrow-btn-bg:#1A223D;
    --chat-online-text-color:#FBCA37;
    --chat-user-name-color:#657099;
    --chat-counter-color:#06060E;
    --chat-message-text-bg:#13192F;
    --chat-message-text-color:#E3EDF9;
    --chat-sendmessage-bg:#131927;
    --chat-sendmessage-form-bg:#1E2639;
    --chat-blue:#3B58E7;
    padding: 24px 1px 24px 0;
    background-color: var(--chat-bg);
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .chat__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  .chat__head__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    h3 {
      font-weight: 900;
      font-size: 20px;
      text-transform: uppercase;
      color: var(--chat-title-color);
    }
  }

  .chat__head__left__status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }

  .chat__head__left__status__dot {
    height: 6px;
    width: 6px;
    background-color: var(--chat-online-text-color);
    border-radius: 50%;
  }

  .chat__head__left__status__text {
    font-weight: 700;
    font-size: 12px;
    color: var(--chat-online-text-color);
  }

  .chat__head__right__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover, &:active {
      outline: none;
    }
  }

  .chat__body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    padding: 0 24px 10px;
    flex: 1 1 auto;

    &::-webkit-scrollbar {
      border-radius: 3px;
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: var(--chat-bg);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: var(--chat-bg);
    }
  }

  &:hover .chat__body::-webkit-scrollbar-thumb {
    background: #313951;
  }

  .chat__body__messages {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .chat__body__messages__message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
  }

  .chat__body__messages__message__context {
    flex: 1;
  }

  .chat__body__messages__message__context__statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat__body__messages__message__context__statistics__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .chat__body__messages__message__context__statistics__info__counter {
    height: 14px;
    min-width: 15px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    font-weight: 800;
    font-size: 9px;
    color: var(--chat-counter-color);

    &.gold {
      background: linear-gradient(180deg, #FCDC14 0%, #FF8733 100%);
    }

    &.silver {
      background: linear-gradient(180deg, #C9E7FD 0%, #6EAFEA 100%);
    }

    &.bronze {
      background: linear-gradient(180deg, #FE9849 0%, #FD571A 100%);
    }
  }

  .chat__body__messages__message__context__statistics__info__name {
    font-weight: 600;
    font-size: 12px;
    color: var(--chat-user-name-color);
  }

  .chat__body__messages__message__context__statistics__time {
    font-weight: 600;
    font-size: 11px;
    color: var(--chat-user-name-color);
  }

  .chat__body__messages__message__context__text {
    background-color: var(--chat-message-text-bg);
    padding: 10px 12px;
    font-weight: 500;
    font-size: 12px;
    color: var(--chat-message-text-color);
    border-radius: 8px;
    margin-top: 5px;
  }

  .chat__body__sendmessage {
    margin-top: 4px;
    background: var(--chat-sendmessage-bg);
    border: 2px solid rgba(49, 57, 81, 0.7);
    padding: 12px 16px;
    border-radius: 8px;
    position: relative;
  }

  .chat__body__sendmessage__form {
    background-color: var(--chat-sendmessage-form-bg);
    height: 36px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .chat__body__sendmessage__form__input {
    flex: 1;
    height: 36px;
    border: none;
    outline: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: transparent;
    font-weight: 600;
    font-size: 13px;
    color: var(--chat-user-name-color);
    padding: 0 16px;

    &::placeholder {
      color: var(--chat-user-name-color);
      opacity: 1;
    }

    &:-ms-input-placeholder, &::-ms-input-placeholder {
      color: var(--chat-user-name-color);
    }

    &:hover, &:focus {
      outline: none;
    }
  }

  .chat__body__sendmessage__form {
    .dropdown-popup-component, .dropdown-popup-toggler {
      background: none;
      border: none;
      position: static;
      width: auto;
    }

    .button {
      height: 36px;
      width: 36px;
    }
  }

  &.closed {
    display: none;
    visibility: hidden;
  }

  .chat__footer {
    margin-top: 15px;
    padding: 0 24px;
  }
}

emoji-picker {
  --num-columns: 4;
  --emoji-size: 1.16rem;
  --background: #131927;
}

@media (max-width: 1200px) {
  chat {
    top: 110px;
  }
}
@media (max-width: 991px) {
  chat {
    top: 61px;
    bottom: 76px;

    #chat-close {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .opened-chat {
    padding-right: 0;
  }
  chat {
    width: 100%;
  }
}