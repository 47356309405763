@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

:root {
  --body-bg:#010205;
}

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  background-color: var(--body-bg);
  height: auto;
  width: 100%;
}

.app {
  min-width: 1080px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden;
  height: 100%;
  //background: url(../../../assets/betrabbit/v3/assets/body-bg4.webp) center top;

  .bg-glow {
    img {
      position: absolute;
      pointer-events: none;

      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          top: $i*770 + px;
        }
      }

      &:nth-child(2n) {
        right: -420px;
      }

      &:nth-child(2n+1) {
        left: -420px;
      }
    }
  }

  &.app-noreg {
    // background: url(../../../assets/betrabbit/v3/assets/body-bg-nouser.png) no-repeat center top;
    background: url(../../../assets/betrabbit/v3/assets/body-bg4.webp) center top;
    background-size: 100%;
  }

  &.app-gamemode {
    background: url(../../../assets/betrabbit/v3/assets/game-bg-example.png) no-repeat top center;
    background-size: contain;
  }
}

.page-title {
  font-weight: 900;
  font-size: 28px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 35px;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .app {
    min-width: unset;
  }
}

// здесь все наши кастомные изменения, нужно подключать заново при изменении
@import 'office.scss';

@import 'fancy-box.scss';
@import 'ui-kit.scss';
@import 'slider.scss';
@import 'table.scss';
@import 'dropdown.scss';
@import 'slider-products.scss';
@import 'slider-banners.scss';
@import 'tab-controls.scss';
@import 'categories-controls.scss';
@import 'iphone-switch.scss';
@import 'catalog';
@import 'pagination.scss';
@import 'popup.scss';
@import 'wheel.scss';
@import 'socials.scss';
@import 'header.scss';
@import 'main.scss';
@import 'sidebar.scss';
@import 'content.scss';
@import 'chat.scss';
@import 'notifications.scss';
@import 'search.scss';
@import 'tapbar.scss';
@import 'homepage-noreg.scss';
@import 'promotions.scss';
@import 'homepage-reg.scss';
@import 'category-page.scss';
@import 'game-mode-page.scss';
@import 'footer.scss';
@import 'bonus-page.scss';

@import 'favorites-page.scss';
@import 'fix.scss';
@import '404.scss';
@import 'promotions-item.scss';
@import 'metamask-email.scss';
@import 'vip_club.scss';
@import 'terms.scss';
