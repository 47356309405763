.button {
  --button-blue-bg:#3B58E7;
  --button-blue-hover:#4C6AFF;
  --button-blue-active:#324CCA;
  --button-red-bg:#E73B3B;
  --button-red-hover:#FF4F4F;
  --button-red-active:#DC3A3A;
  --button-text-white:#ffffff;
  --button-font-size:14px;
  --button-font-weight:700;
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  color: var(--button-text-white);
  transition: background-color ease-in-out .2s;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 60px rgba(59, 88, 231, 0.2), 0px 4px 40px rgba(0, 0, 0, 0.16);

  &:hover, &:active {
    outline: none;
  }
}

.button__blue {
  background-color: var(--button-blue-bg);

  &:hover {
    background-color: var(--button-blue-hover);
  }

  &:active {
    background-color: var(--button-blue-active);
  }
}

.button__red {
  background-color: var(--button-red-bg);
  width: 106px;

  &:hover {
    background-color: var(--button-red-hover);
  }

  &:active {
    background-color: var(--button-red-active);
  }
}

.view-all {
  --view-all-bg:#131927;
  --view-all-border-color:rgba(49, 57, 81, 0.7);
  --view-all-text-color:#EEECEC;
  height: 35px;
  width: auto;
  background: var(--view-all-bg);
  border: 1.5px solid var(--view-all-border-color);
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 15px;

  span {
    font-weight: 600;
    font-size: 13px;
    color: var(--view-all-text-color);
  }

  &:hover, &:active {
    outline: none;
  }
}