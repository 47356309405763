.categories-controls {
  --categories-button-color:#657099;
  --categories-box-shadow-blue:#3B58E7;
  --categories-text-color:#ffffff;
  --categories-button-blue-hover:#4C6AFF;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0!important;

  button {
    border: none;
    border-radius: 16px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    background: none;
    font-weight: 600;
    font-size: 13px;
    height: 32px;
    cursor: pointer;
    color: var(--categories-button-color);
    box-shadow: none;
    transition: all ease-in-out 0.2s;

    &:hover {
      color: var(--categories-button-blue-hover);
    }

    &.active {
      margin-right: 20px;
      background-color: var(--categories-box-shadow-blue);
      color: var(--categories-text-color);

      &:hover {
        background-color: var(--categories-button-blue-hover);
      }
    }
  }
}

.categories-controls-search {
  --categories-search-bg: #131927;
  --categories-search-color: #ffffff;
  --categories-search-border: #313951;
  position: relative;
  margin-right: 2px;
  width: 217px;

  input[type="search"]::-webkit-search-decoration {
    display: none;
  }

  input {
    background-color: var(--categories-search-bg);
    border: 1.5px solid var(--categories-search-border);
    border-radius: 8px;
    color: var(--categories-search-color);
    display: block;
    font-size: 13px;
    font-weight: 500;
    height: 35px;
    margin: 0 auto;
    outline: none;
    padding: 0 40px 0 16px;
    width: 100%;

    &::placeholder {
      color: var(--categories-search-color);
    }

  }

  img {
    position: absolute;
    top: 10px;
    right: 16px;
  }
}
.categories-controls-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  gap: 14px;
}
.mobile-categories-controls-search {
  display: none;
  width: 100%;
  margin-top: 14px;
}
@media (max-width: 1740px) {
  .opened-chat.opened-sidebar {

    .categories-controls-search {
      display: none;
    }

    .mobile-categories-controls-search {
      display: block;
    }

    .categories-controls {
      width: 100%;
    }
    .categories-controls-wrap {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1600px) {

  .opened-chat {
    .categories-controls-search {
      display: none;
    }

    .mobile-categories-controls-search {
      display: block;
    }
  }
}
@media (max-width: 1440px) {
  .opened-sidebar {

    .categories-controls-search {
      display: none;
    }

    .mobile-categories-controls-search {
      display: block;
    }
  }
  .opened-chat {
    .categories-controls {
      width: 100%;
    }
    .categories-controls-wrap {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1366px) {
  .opened-sidebar {
    .categories-controls {
      width: 100%;
    }
    .categories-controls-wrap {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
      }
    }
  }

  .categories-controls-search {
    display: none;
  }

  .mobile-categories-controls-search {
    display: block;
  }
}
@media (max-width: 991px) {
  .categories-controls {
    width: 100%;
  }
  .categories-controls-wrap {
    flex-direction: column;

    .tab-controls__select {
      width: 100%;
    }
  }
}