.splide-products {
  --slider-products-label-color:#35210E;
  --slider-products-freeze:#46D3FF;
  --slider-products-fire:#FF8946;
  --slider-products-cash:#6FC820;
  --slider-products-hover-text-color:#FFFFFF;
  --slider-products-hover-primary-color:#3B58E7;
  --slider-products-hover-active-color: #4C6AFF;
  width: 100%;
  .splide__arrows {
    display: none !important;
    visibility: hidden !important;
  }

  .splide div ul li  {
    display: block;
    border-radius: 14px;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    -webkit-perspective: 1000;
    -webkit-transform: translateZ(0);

    .catalog__list__item-image {
      overflow: hidden;
      padding-top: 133.34%;
      position: relative;

      .catalog__list__item-image-inner {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform .1s ease-in-out;

        img {
          display: block;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
        }
        /*
        img {
          height: auto;
          max-width: 100%;
          vertical-align: middle;
        }

        .img-wide {
          display: block;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
        }
        */
      }
    }

    &:hover img.bg {
      transform: scale(1.1);
    }

    .item-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity .3s;
      opacity: 0;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
      z-index: 2;

      .item-hover-title {
        position: absolute;
        top: 0;
        color: var(--slider-products-hover-text-color);
        text-align: center;
        padding: 16px 30px;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
        pointer-events: none;
      }

      .item-hover-provider {
        position: absolute;
        top: 80%;
        color: var(--slider-products-hover-text-color);
        text-align: center;
        // padding: 10% 10%;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
      }

      .item-hover-favorite {
        position: absolute;
        top: 16px;
        right: 11px;
        display: flex;

        .item-hover-favorite-active {
          display: none;
        }

        &.active,
        &:hover {
          img {
            display: none;

            &.item-hover-favorite-active {
              display: inline;
            }
          }
        }
      }

      .item-hover-play {
        background: var(--slider-products-hover-primary-color);
        border-radius: 50%;
        width: 49px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2px;

        &:hover {
          background: var(--slider-products-hover-active-color);
        }
      }
    }

    &:hover {
      .item-hover {
        opacity: 1;
      }
    }

    .label {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      padding: 6px 8px;
      border-radius: 0 0 16px 0;

      span {
        font-weight: 700;
        font-size: 12px;
        color: var(--slider-products-label-color);
      }

      &.cash {
        background-color: var(--slider-products-cash);
      }

      &.fire {
        background-color: var(--slider-products-fire);
      }

      &.freeze {
        background-color: var(--slider-products-freeze);
      }
    }

    &.cash {
      border: 2px solid var(--slider-products-cash);
    }

    &.fire {
      border: 2px solid var(--slider-products-fire);
    }

    &.freeze {
      border: 2px solid var(--slider-products-freeze);
    }
  }
}
@media (max-width: 580px) {
  .splide-products {
    .slider-component__title {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
