.promotions-item-page {
  --promotions-item-title: #FFFFFF;
  --promotions-item-text: #657099;
  --promotions-item-link: #3B58E7;
  --promotions-toggler-bg: #13192f;

  .page-title-secondary {
    font-size: 24px;
    margin-top: 36px;
    margin-bottom: 21px;
  }

  .promotions-item {
    margin-top: 23px;

    .promotions-item-image {
      margin-bottom: 28px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    h4 {
      color: var(--promotions-item-title);
      font-size: 24px;
      font-weight: 900;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 14px;
    }

    p {
      color: var(--promotions-item-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .tournament-block {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    gap: 20px;

    .tournament-link {
      min-width: 212px;
      height: 46px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .tournament-toggler {
      background: var(--promotions-toggler-bg);
      border: 0;
      border-radius: 9px;
      width: 100%;
      padding: 11px 18px 11px 20px;

      .tournament-toggler-wrap {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .tournament-toggler-title {
          display: flex;
          align-items: center;
          gap: 10px;

          h6 {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: var(--promotions-item-title);
          }
        }

      }

      .tournament-toggler-text {
        display: none;
        color: var(--promotions-item-text);
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        text-align: left;
        padding: 21px 0 24px;

        h6 {
          font-size: 14px;
          font-weight: 800;
          line-height: 19px;
          margin: 6px 0 6px;
        }
      }

      &.show {
        .tournament-toggler-wrap .faq-arrow {
          transform: rotate(180deg);
        }
        .tournament-toggler-text {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .opened-sidebar.opened-chat .promotions-item-page .tournament-block {
    flex-wrap: wrap;
  }
}
@media (max-width: 580px) {
  .promotions-item-page .tournament-block {
    flex-wrap: wrap;
  }
}