.footer {
  --footer-border:rgba(48, 57, 86, 0.5);
  --footer-nav-link-color:#5A6477;
  --footer-nav-link-color-hover:#ffffff;
  --footer-delimiter-color:#161B28;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  border-radius: 16px;
  border-top: 2px solid var(--footer-border);
  background: #0e121d;
  padding-left: 70px;

  img.footer-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }
}

.footer__content {
  flex: 1;
  width: 1185px;
  max-width: 100%;
  margin: 0 auto;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.footer__content__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 2px solid var(--footer-delimiter-color);
  padding-bottom: 34px;
  gap: 30px;
}

.footer__content__top__block {
  flex: 1;
  max-width: 110px;

  &:last-child {
    max-width: 457px;
  }

  nav {
    width: fit-content;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;
      margin: 0;
      padding: 0;
      width: auto;

      li {
        width: fit-content;

        a {
          width: fit-content;
          font-weight: 500;
          font-size: 14px;
          transition: color ease-in-out .2s;
          color: var(--footer-nav-link-color);
          text-decoration: none;

          &:hover {
            color: var(--footer-nav-link-color-hover);
          }
        }
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    text-align: right;
    color: var(--footer-nav-link-color);
  }
}

.footer__content__top__block__payments {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  img {
    flex: 1;
  }
}

.footer__content__down {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.footer__content__down__block__privacy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;

  a {
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    transition: color ease-in-out .2s;
    color: var(--footer-nav-link-color);
    text-decoration: none;

    &:hover {
      color: var(--footer-nav-link-color-hover);
    }
  }
}

.footer__content__down__block__copyright {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  p {
    font-weight: 400;
    font-size: 12px;
    color: var(--footer-nav-link-color);
    text-align: right;
  }

  img {
    mix-blend-mode: luminosity;
  }
}
.opened-sidebar+footer {
  padding-left: 238px;
}
.opened-chat+footer {
  padding-right: 382px;
}
@media (max-width: 1790px) {
  .footer__content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 1200px) {
  .footer .empty-spaces-chat,
  .footer .empty-spaces-sidebar {
    display: none;
  }
  .opened-chat+.footer .empty-spaces-chat,
  .opened-notifications+.footer .empty-spaces-chat {
    display: block;
  }
  .footer__content__top__block {
    margin-bottom: 20px;

    &:last-child {
      flex: 100%;
    }
    p {
      text-align: left;
    }
  }

  .footer__content__down {

    .footer__content__down__block {

      &:last-child {
        flex: 100%;

        .footer__content__down__block__copyright {
          justify-content: flex-end;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    padding-left: 0;
    padding-bottom: 76px;
  }
  .opened-sidebar+footer {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .opened-chat+footer {
    padding-right: 0;
  }
  .empty-spaces-chat {
    display: none;
  }
}
@media (max-width: 420px) {
  .footer__content__down,
  .footer__content__down .footer__content__down__block:last-child .footer__content__down__block__copyright {
    justify-content: center;
  }
}
