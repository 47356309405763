.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 69px;
  padding-left: 70px;
  transition: all ease-in-out 0.2s;
}

@media (max-width: 1200px) {
  .main {
    padding-top: 110px;
  }
}
@media (max-width: 991px) {
  .main {
    padding-top: 61px;
    padding-left: 0;
  }
}