
#tapbar {
  --tapbar-bg: #0E121D;
  --tapbar-color: #B9C7D6;
  --tapbar-color-active: #FFFFFF;
  --tapbar-color-special: #E73B3B;

  background-color: var(--tapbar-bg);
  //background: url(../../dist/assets/tapbar/tapbar-bg.svg) bottom center no-repeat;
  border-top: 0;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px;
  position: fixed;
  height: 76px;
  width: 100%;
  z-index: 104;
  box-shadow: 0px -2px 30px 0px #00000014;

  .tapbar-item {
    cursor: pointer;
    height: 66px;
    width: 40px;
    position: relative;
    text-decoration: none!important;

    .tapbar-button {
      align-items: center;
      display: flex;
      flex-direction: column;
      color: var(--tapbar-color);
      height: 100%;
      justify-content: center;
      position: relative;
      text-decoration: none;
      transform: translateZ(0);
      width: 100%;



      &:before {
        background: 0 0;
        content: "";
        filter: blur(0);
        height: 0;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: -15px;
        transform: translate(-50%);
        transition: all .2s ease-in-out;
        width: 0;
        z-index: -1;
      }

      .tapbar-button-img {
        display: flex;
        align-items: center;
        height: 45px;
        margin-bottom: 2px;
      }

      span {
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.1px;
        transition: color .1s ease-in-out;
        white-space: nowrap;
      }

    }
    &:not(.tapbar-item-special) {
      .tapbar-button.active {
        color: var(--tapbar-color-active);

        &:before {
          background: #3B58E7;
          content: "";
          filter: blur(15px);
          width: 59px;
          height: 58px;
          left: 50%;
          opacity: 0.4;
          position: absolute;
          top: -45px;
          transform: translate(-50%);
          transition: all .2s ease-in-out;
          z-index: -1;
          pointer-events: none;
        }

        .tapbar-button-img {
          filter: brightness(0) invert(1);
        }

      }
    }
  }

  .tapbar-item-special {
    width: 60px;

    .tapbar-button {

      .tapbar-button-img {
        margin-top: -2px;
        margin-bottom: 4px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--tapbar-color-special);
      }
    }
  }
}

@media (min-width: 992px) {
  #tapbar {
    display: none;
  }
}