.socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover, &:active {
      outline: none;
    }
  }
}
