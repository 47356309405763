.blue-shadow-home-reg-left._one, .blue-shadow-home-reg-right._one {
  top: 1500px !important;
}

.blue-shadow-home-reg-left._two, .blue-shadow-home-reg-right._two {
  top: 2500px !important;
}

.homepage_reg {
  margin-top: 27px;

  /** override */

  .splide {
    --splide-text-white:#ffffff;
    --splide-text-white-opacity:rgba(255, 255, 255, 0.8);
    --splide-welcome-bonus-bg:#E7833B;
  }

  .splide__slide__context {
    width: 100%;
    height: auto;
    border: 1.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    img.bg {
      display: block;
      border-radius: 24px;
      height: auto;
      width: 100%;
      position: relative;
      z-index: 0;
    }
  }

  .splide__slide__context__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 30px 21px;
  }

  .splide__slide__context__info__text {
    max-width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    h1 {
      margin: 1px 0 0;
      font-weight: 900;
      font-size: 24px;
      line-height: 26px;
      text-transform: uppercase;
      color: var(--splide-text-white);
    }

    p {
      margin-top: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: var(--splide-text-white-opacity);
    }

    .button {
      margin-top: 28px;
    }
  }

  .splide__slide__context__info__controls {
    position: absolute;
    top: -25px;
  }

  .splide__slide__context__info__controls__bonus {
    background-color: var(--splide-welcome-bonus-bg);
    box-shadow: 0px 0px 30px rgba(231, 131, 59, 0.5), 0px 4px 32px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    color: var(--splide-text-white);
  }

  .splide__arrows {
    display: flex !important;
    justify-content: center;
    align-items: flex-end;
    visibility: visible !important;
  }

  .splide__arrow {
    position: absolute;
    background-color: transparent;
    opacity: 1;
    left: -30px;

    svg {
      fill: #fff;
      width: 20px;
      height: 20px;
    }

    &.splide__arrow--next {
      left: unset;
      right: -30px;
    }
  }

  .splide__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
    bottom: 0;

    li {
      flex: 1;

      .splide__pagination__page {
        width: 100%;
        display: block;
        border-radius: 0;
        margin: 0;
        height: 4px;

        &.is-active {
          transform: none;
          background-color: var(--splide-text-white);
          opacity: 1;
        }
      }

      &:first-child .splide__pagination__page {
        border-top-left-radius: 16px;
      }

      &:last-child .splide__pagination__page {
        border-top-right-radius: 16px;
      }
    }
  }
}

.homepage_reg__controls__tab_controls {
  margin-top: 32px;
}

.homepage_reg__controls__categories {
  margin-top: 16px;
}

.homepage_reg__catalog {
  margin-top: 40px;
  width: 100%;
}

@media (max-width: 1366px) {
  .content {
    padding: 0 25px;
  }
}
@media (max-width: 766px) {
  .content {
    padding: 0 13px;
  }
  .homepage_reg .splide__arrow {
    left: 0px;
  }
  .homepage_reg .splide__arrow.splide__arrow--next {
    right: 0px;
  }
}
