.blue-shadow-home-no-reg-left._one, .blue-shadow-home-no-reg-right._one {
  top: 1300px !important;
}

.homepage_noreg {
  --homepage_noreg-text-color:#ffffff;
  --homepage_noreg-text-2-color:rgba(255, 255, 255, 0.5);
  --homepage_noreg-text-color-opacity:rgba(255, 255, 255, 0.8);
  --homepage_noreg-box-shadow-blue:#3B58E7;
  --homepage_noreg-box-shadow-black:rgba(0, 0, 0, 0.16);
  --homepage_noreg-toggle-btn-border-color:rgba(255, 255, 255, 0.1);
  --homepage_noreg-crypto-text-color:#525D82;
  --homepage_noreg-label-color:#35210E;
  --homepage_noreg-freeze:#46D3FF;
  --homepage_noreg-fire:#FF8946;
  --homepage_noreg-cash:#6FC820;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /** Override styles */
}

.homepage_noreg__hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 29px;
  width: 100%;
}

.homepage_noreg__hero__title {
  align-self: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.homepage_noreg__hero__title__context {
  h1 {
    font-weight: 900;
    font-size: 42px;
    line-height: 48px;
    color: var(--homepage_noreg-text-color);
    margin: 12px 0 6px;
    padding: 0;
    text-transform: uppercase;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: var(--homepage_noreg-text-color-opacity);
    opacity: 0.7;
  }

  .button {
    min-width: 127px;
    height: 46px;
    margin-top: 28px;
    border-radius: 8px;
    box-shadow: 0px 0px 100px var(--homepage_noreg-box-shadow-blue), 0px 4px 40px var(--homepage_noreg-box-shadow-black);
  }
}

.homepage_noreg__hero__img {
  position: relative;
  min-height: 443px;
  width: auto;
  flex: 1;
  z-index: -1;

  img {
    position: absolute;
    top: -69px;
    // left: -31px;
  }
}

.homepage_noreg__tab_buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 14px;
}

.homepage_noreg__tab_buttons__button {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  flex: 1;
  height: 80px;
  display: flex;
  align-items: center;
  min-width: 215px;

  &:nth-child(1) {
    order: 1;
  }
  &:nth-child(2) {
    order: 2;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:nth-child(4) {
    order: 4;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #fff;
    opacity: 0.1;
    border-radius: 16px;
  }

  &:hover, &:active {
    outline: none;
  }

  img {
    &.bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &.img {
      transition: transform 0.2s ease;
      position: absolute;
      pointer-events: none;
    }
  }

  &:hover .img {
    transform: scale(1.2);
  }
}

.homepage_noreg__tab_buttons__button__title {
  position: absolute;
  top: 18px;
  left: 43%;

  h4 {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 4px;
    text-transform: uppercase;
    color: var(--homepage_noreg-text-color);
    text-align: left;
  }

  p {
    font-weight: 600;
    font-size: 13px;
    color: var(--homepage_noreg-text-2-color);
    text-align: left;
  }
}

.homepage_noreg__tab_buttons__button {
  &:nth-child(1) img.img {
    top: -80px;
    left: -45px;
  }

  &:nth-child(2) {

    img.img {
      top: -89px;
      left: -50px;
    }
  }

  &:nth-child(3) img.img {
    top: -77px;
    left: -30px;
  }

  &:nth-child(4) img.img {
    top: -97px;
    left: -62px;
  }
}

.homepage_noreg__crypto_info {
  width: 100%;
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.homepage_noreg__crypto_info__block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  p {
    color: var(--homepage_noreg-crypto-text-color);

    &:nth-child(1) {
      font-weight: 500;
      font-size: 32px;
      text-transform: uppercase;
    }

    &:nth-child(2) {
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.homepage_noreg__tab_controls {
  margin-top: 42px;
}

.homepage_noreg__categories {
  margin-top: 15px;
}

.homepage_noreg__providers {
  margin-top: 64px;
}

.homepage_noreg__providers__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage_noreg__providers__title__text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h2 {
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    color: var(--homepage_noreg-text-color);
  }
}

.homepage_noreg__providers__list {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 11px;
  flex-wrap: wrap;

  &>img {
    max-width: calc(33.333% - 7.33px);
    height: auto;
  }

  .homepage_noreg__providers__list__item {
    flex: 1;
    display: block;
    height: 200px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}


.tab-controls-statistic {
  --tab-controls-statistic-frame-bg:#131927;
  --tab-controls-statistic-frame-border:rgba(49, 57, 81, 0.7);
  background: var(--tab-controls-statistic-frame-bg);
  border: 1.5px solid var(--tab-controls-statistic-frame-border);
  border-radius: 8px;
  padding: 6px 5px 6px 5px;
  margin-top: 69px;

  .tab-controls__buttons {
    height: auto;

    button {
      min-width: 150px;
      height: 36px;
      border-radius: 6px;
    }
  }
}

.homepage_noreg__statistic_tab_content {
  --statistic-table-bg: #090b16;
  margin-top: 27px;
  width: 100%;

  &.table-wrapper-stats {
    overflow-x: auto;

    &::-webkit-scrollbar-thumb {
      background-color: #2a3546;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #0d131ccc;
      border-radius: 2px;
    }
    &::-webkit-scrollbar {
      background-color: #0d131ccc;
      border-radius: 2px;
      height: 4px;
      width: 4px;
    }
  }

  .table {
    --statistic-table-tr-bg:#131927;
    --statistic-table-tr-border-color:rgba(49, 57, 81, 0.7);
    border-spacing: 0 10px;

    thead {
      .game-name {
        width: 23%;
        padding-left: 49px;
        position: sticky;
        left: 0;
        z-index: 2;
      }

      th {
        background: var(--statistic-table-bg);
        padding: 0px 22px;
      }

      .username {
        width: 25%;
      }

      .bet-amount {
        width: 20%;
      }

      .multiplier {
        width: 10%;
        text-align: center;
      }

      .profit-amount {
        width: 22%;
        padding-left: 50px;
      }
    }

    tbody {
      .game-name {
        width: 23%;
        position: sticky;
        left: 0;
        z-index: 2;

        .container {
          padding-left: 49px;
        }
      }

      .username {
        width: 25%;
      }

      .bet-amount {
        width: 20%;
      }

      .multiplier {
        width: 10%;
        text-align: center;

        .container {
          justify-content: center;
        }
      }

      .profit-amount {
        width: 22%;

        .container {
          padding-left: 50px;
        }
      }

      tr td {
        background: var(--statistic-table-tr-bg);
        border-top: 1.5px solid var(--statistic-table-tr-border-color);
        border-bottom: 1.5px solid var(--statistic-table-tr-border-color);

        &:first-child {
          border-left: 1.5px solid var(--statistic-table-tr-border-color);
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-right: 1.5px solid var(--statistic-table-tr-border-color);
          border-radius: 0 8px 8px 0;
        }

        .container {
          padding: 8px 22px 7px;
        }
      }
    }
  }
}



.blue-shadow-vip-club-left, .blue-shadow-vip-club-right {
  top: 1500px !important;
}

.blue-shadow-vip-club-left:last-child, .blue-shadow-vip-club-right:last-child {
  top: 2300px !important;
}

.blue-shadow-vip-club-left.blue-shadow-vip-club-right-2, .blue-shadow-vip-club-right.blue-shadow-vip-club-right-2 {
  top: 2100px !important;
}


// @media (min-width: 1366px) and (max-width: 1600px) {
//   .homepage_noreg__hero__img img {
//     left: -310px;
//   }
// }


@media (max-width: 1600px) {
  .homepage_noreg__crypto_info {
    justify-content: flex-start;
  }
  .homepage_noreg__tab_buttons__button {
    flex: calc(50% - 7px) 0 0;

    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 2;
    }
  }
}

@media (max-width: 1200px) {
  .opened-notifications.opened-sidebar .homepage_noreg__tab_buttons__button,
  .opened-chat.opened-sidebar .homepage_noreg__tab_buttons__button {
    flex: 100% 0 0;

    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
  }
}


@media (max-width: 991px) {
  .homepage_noreg__providers__list .homepage_noreg__providers__list__item {
    flex: calc(50% - 6px) 0 0;
    height: auto;
  }
  .opened-chat .homepage_noreg__providers__list .homepage_noreg__providers__list__item,
  .opened-notifications .homepage_noreg__providers__list .homepage_noreg__providers__list__item {
    flex: 100% 0 0;
  }
  .opened-chat .homepage_noreg__tab_buttons__button,
  .opened-notifications .homepage_noreg__tab_buttons__button {
    flex: 100% 0 0;

    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
  }
  .homepage_noreg__hero__img img {
    left: -150px;
  }
}


@media (max-width: 900px) {
  .homepage_noreg__statistic_tab_content .table thead .game-name,
  .homepage_noreg__statistic_tab_content .table tbody .game-name {
    position: sticky;
    left: 0;
    z-index: 2;
  }
}


@media (max-width: 640px) {
  .homepage_noreg__tab_buttons__button {
    flex: 100% 0 0;

    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
  }
  .homepage_noreg__hero {
    flex-direction: column;
  }
  .homepage_noreg__hero__title {
    order: 2;
    margin-top: -225px;
    box-shadow: 0px 2px 10px 13px #0000004D;
    background: #0000004D;
  }
  .homepage_noreg__hero__title__context {
    text-align: center;
  }
  .homepage_noreg__hero__title__context h1 {
    font-size: 28px;
    line-height: 34px;
  }
  .homepage_noreg__hero__title__context h1 br {
    display: none;
  }
  .homepage_noreg__hero__title__context .button {
    margin: 7px auto 30px;
  }
  .homepage_noreg__hero__img {
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: calc(100% + 48px);
    margin: -70px -24px 0;
  }
  .homepage_noreg__hero__img img {
    position: static;
    display: block;
    width: auto!important;
    transform-style: flat!important;
    transform: scale(0.9);
  }
  .homepage_noreg__providers__list>img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}


@media (max-width: 580px) {
  .homepage_noreg__providers__list .homepage_noreg__providers__list__item {
    flex: 100% 0 0;
  }
}
