.tab-controls {
  --tab-controls-frame-bg:rgba(19, 25, 39, 0.5);
  --tab-controls-frame-border:rgba(49, 57, 81, 0.4);
  --tab-controls-button-color:#657099;
  --tab-controls-text-color:#ffffff;
  --tab-controls-text-color-hover:#4C6AFF;
  --tab-controls-box-shadow-blue:#3B58E7;
  --tab-controls-box-shadow-black:rgba(0, 0, 0, 0.16);
  --tab-controls-button-blue-hover:#4C6AFF;
  --tab-controls-button-blue-active:#324CCA;
  background: var(--tab-controls-frame-bg);
  border: 1px solid var(--tab-controls-frame-border);
  border-radius: 8px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}
.mobile-provider-filter-wrap {
  display: none;
  margin-top: 15px;
}
.mobile-filter-wrap {
  display: none;
  margin-top: 15px;

  .dropdown-popup__link {
    justify-content: flex-start;
    gap: 10px;

    .dropdown-popup__link-image {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: auto;
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }
}
.tab-controls-fullwidth {
  width: 100%;
}

.tab-controls__buttons {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 40px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all ease-in-out 0.2s;
    padding: 0 15px;
    margin-right: 3px;

    span {
      &.title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: var(--tab-controls-button-color);
      }

      &.label {
        margin-left: -3px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        padding: 0px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        color: var(--tab-controls-text-color);
      }
    }

    &:hover, &:active {
      outline: none;
    }

    &:hover {
      color: var(--tab-controls-text-color-hover);
    }

    &.active {
      margin-right: 17px;
      background-color: var(--tab-controls-box-shadow-blue);
      box-shadow: 0px 0px 100px var(--tab-controls-box-shadow-blue), 0px 4px 40px var(--tab-controls-box-shadow-black);
    }

    &:hover span.title {
      color: var(--tab-controls-text-color-hover);
    }

    &.active {
      span.title {
        color: var(--tab-controls-text-color);
        color: var(--tab-controls-text-color);
      }

      transition: all ease-in-out 0.2s;

      &:hover {
        background-color: var(--tab-controls-button-blue-hover);
      }
    }

    &:active {
      background-color: var(--tab-controls-button-blue-active);
    }
  }
}

.tab-controls__select {
  .dropdown-popup-component {
    border-width: 1.5px;
  }

  .dropdown-popup-toggler {
    padding: 5px 18px 5px 15px;
    min-width: 218px;

    .title {
      gap: 9px;

      span {
        color: #fff;
      }
    }
  }
}
@media (max-width: 1740px) {
  .opened-chat.opened-sidebar {
    .tab-controls {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
        margin-top: 10px;
      }

      .tab-controls__buttons {
        width: 100%;

        button {
          justify-content: flex-start;
          margin-right: 0!important;
        }
      }
    }
    .desktop-tab-controls {
      display: none;
    }
    .mobile-provider-filter-wrap,
    .mobile-filter-wrap {
      display: block;
    }
  }
}
@media (max-width: 1600px) {

  .opened-chat {
    .desktop-tab-controls {
      display: none;
    }
    .mobile-provider-filter-wrap,
    .mobile-filter-wrap {
      display: block;
    }
  }
}
@media (max-width: 1440px) {
  .opened-sidebar {

    .desktop-tab-controls {
      display: none;
    }
    .mobile-provider-filter-wrap,
    .mobile-filter-wrap {
      display: block;
    }
  }
}
@media (max-width: 1366px) {

  .desktop-tab-controls {
    display: none;
  }
  .mobile-provider-filter-wrap,
  .mobile-filter-wrap {
    display: block;
  }
  .opened-chat {
    .tab-controls {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
        margin-top: 10px;
      }

      .tab-controls__buttons {
        width: 100%;

        button {
          justify-content: flex-start;
          margin-right: 0!important;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .opened-chat.opened-sidebar {
    .tab-controls {
      .tab-controls__buttons {
        button {
          width: 100%;
        }
      }
    }
  }
  .opened-sidebar {
    .tab-controls {
      flex-direction: column;

      .tab-controls__select {
        width: 100%;
        margin-top: 10px;
      }

      .tab-controls__buttons {
        width: 100%;

        button {
          justify-content: flex-start;
          margin-right: 0!important;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .tab-controls {
    flex-direction: column;

    .tab-controls__select {
      width: 100%;
      margin-top: 10px;
    }

    .tab-controls__buttons {
      width: 100%;

      button {
        justify-content: flex-start;
        margin-right: 0!important;
      }
    }
  }
}
@media (max-width: 460px) {
  .tab-controls {

    .tab-controls__buttons {

      button {
        width: 100%!important;
      }
    }
  }
}