
.opened-sidebar {
  padding-left: 238px;

  sidebar {
    width: 238px;
  }
}
sidebar {
  display: block;
  position: fixed;
  top: 69px;
  left: 0;
  bottom: 0;
  z-index: 50;
  width: 70px;

  ::-webkit-scrollbar {
    border-radius: 3px;
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--sidebar-bg);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--sidebar-bg);
  }

  &:hover ::-webkit-scrollbar-thumb {
    background: #313951;
  }

  .blue-shadow {
    position: absolute;
    filter: blur(100px);
    left: 0;
    top: 600px;
    z-index: -1;
  }

  .sidebar {
    --sidebar-bg:#0E121D;
    --sidebar-toggle-btn-text-color:#ffffff;
    --sidebar-toggle-btn-bg:#13192F;
    --sidebar-delimiter-color:#282F48;
    --sidebar-nav-link-color:#B9C7D6;
    --sidebar-support-bg:#131927;
    --sidebar-support-text-color:#5A6477;
    --sidebar-counter-bg:#E73B3B;
    --sidebar-counter-text-color:#ffffff;
    background-color: var(--sidebar-bg);
    position: relative;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .sidebar__body {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: static;
  }

  .sidebar__footer {
    padding-bottom: 21px;
  }

  .sidebar .devider {
    display: block;
    width: 81%;
    margin: 22px auto;
    margin-bottom: 0;
    height: 1px;
    background-color: var(--sidebar-delimiter-color);
  }

  .sidebar__toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    padding: 24px 22px;
    padding-bottom: 0;
  }

  .sidebar__toggle__button {
    flex: 1;
    border-radius: 8px;
    width: 93px;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: var(--sidebar-bg);
    overflow: hidden;

    &.sb-wheel {
      background-color: var(--sidebar-bg);
      background: #4c1354;
    }

    .button-wheel-animation {
      animation: rolling-wheel 3s linear infinite;
      height: 38px;
      width: 38px;
      position: absolute;
      left: -10px;
      bottom: -13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 110%;
        height: auto;
        position: static;
      }
    }

    .button-wheel-animation-background {
      height: auto;
      width: 53px;
      position: absolute;
      left: -17px;
      bottom: -28px;
      z-index: 2;

      img {
        width: 100%;
        height: auto;
        position: static;
      }
    }

    &:hover, &:active {
      outline: none;
    }

    img {
      width: 93px;
      height: 40px;
      position: absolute;
      object-fit: contain;
      top: 0;
      left: 0;
    }

    span {
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--sidebar-toggle-btn-text-color);
      position: relative;
      z-index: 1;
    }

    &:first-child span {
      padding-left: 8px;
    }

    &:last-child span {
      padding-left: 12px;
    }
  }

  .sidebar__nav {
    padding: 0;
    margin-top: 10px;

    nav {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        width: auto;

        li {
          width: 100%;

          a {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 8px;
            gap: 16px;
            font-weight: 700;
            font-size: 14px;
            padding: 7px 18px;
            color: var(--sidebar-nav-link-color);
            text-decoration: none;
            transition: all ease-in-out .2s;

            &:hover {
              color: var(--sidebar-toggle-btn-text-color);
              background-color: var(--sidebar-toggle-btn-bg);
            }

            img {
              transition: transform 0.2s ease;
            }

            &:hover img {
              transform: scale(1.25);

              &.hover {
                filter: brightness(0) invert(1);
              }
            }

            span.counter {
              background-color: var(--sidebar-counter-bg);
              height: 16px;
              width: auto;
              padding: 2px 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 700;
              font-size: 11px;
              color: var(--sidebar-counter-text-color);
              border-radius: 8px;
            }

            &.crown {
              position: relative;

              img {
                &:first-child {
                  position: absolute;
                  z-index: 1;
                  top: -10px;
                  left: -8px;
                }

                &:nth-child(2) {
                  position: relative;
                  top: -4px;
                  z-index: 3;
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar__controls {
    margin-top: 20px;
    padding: 0 20px;
  }

  .sidebar__controls__support {
    border: none;
    outline: none;
    background-color: var(--sidebar-support-bg);
    cursor: pointer;
    height: 40px;
    width: 100%;
    padding: 13px 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    border-radius: 8px;

    span {
      font-weight: 700;
      font-size: 13px;
      color: var(--sidebar-support-text-color);
    }
  }

  .sidebar__controls .socials {
    margin-top: 10px;
  }

  .sidebar.closed {
    width: fit-content;

    .sidebar__toggle {
      display: block;
      text-align: center;
      padding: 24px 10px;

      .sb-cash, .sb-wheel {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }

      .sb-cash img, .sb-wheel img {
        width: 40px;
      }

      .sb-cash span {
        display: none;
        visibility: hidden;
      }

      .sb-wheel {
        span {
          display: none;
          visibility: hidden;
        }

        margin-top: 10px;
      }
    }

    .sidebar__nav nav {
      margin: 0 auto;

      ul li a {
        justify-content: center;

        span:not(.counter) {
          display: none;
          visibility: hidden;
          position: absolute;
          left: calc(100% - 5px);
          color: var(--sidebar-toggle-btn-text-color);
          background-color: var(--sidebar-toggle-btn-bg);
          white-space: nowrap;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 15px;
          border-radius: 0 8px 8px 0;
        }

        &:hover span:not(.counter) {
          display: flex;
          visibility: visible;
        }

        .counter {
          display: block;
          visibility: visible;
          position: absolute;
          margin-top: -30px;
          right: 0;
        }

        &.crown {
          position: relative;

          img:first-child {
            left: -7px;
          }
        }
      }

      &.sidebar__nav__blank ul li a img {
        height: 30px;
        width: 30px;
      }
    }

    .sidebar__controls {
      display: none;
      visibility: hidden;
    }
  }
}

@keyframes rolling-wheel {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 1200px) {
  sidebar {
    top: 110px;
  }
}
@media (max-width: 991px) {
  sidebar {
    top: 61px;
    z-index: 100;
    bottom: 76px;
    width: 0;

    .sidebar.closed {
      display: none;
    }
/*
    .sidebar {
      width: fit-content;

      .sidebar__toggle {
        display: block;
        text-align: center;
        padding: 24px 10px;

        .sb-cash, .sb-wheel {
          display: block;
          width: 40px;
          height: 40px;
          margin: 0 auto;
        }

        .sb-cash img,
        .sb-wheel img {
          width: 40px;
        }

        .sb-cash span {
          display: none;
          visibility: hidden;
        }

        .sb-wheel {
          span {
            display: none;
            visibility: hidden;
          }

          margin-top: 10px;
        }
      }

      .sidebar__nav nav {
        margin: 0 auto;

        ul li a {
          justify-content: center;

          span:not(.counter) {
            display: none;
            visibility: hidden;
            position: absolute;
            left: calc(100% - 5px);
            color: var(--sidebar-toggle-btn-text-color);
            background-color: var(--sidebar-toggle-btn-bg);
            white-space: nowrap;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 15px;
            border-radius: 0 8px 8px 0;
          }

          &:hover span:not(.counter) {
            display: flex;
            visibility: visible;
          }

          .counter {
            display: block;
            visibility: visible;
            position: absolute;
            margin-top: -30px;
            right: 0;
          }

          &.crown {
            position: relative;

            img:first-child {
              left: -7px;
            }
          }
        }

        &.sidebar__nav__blank ul li a img {
          height: 30px;
          width: 30px;
        }
      }

      .sidebar__controls {
        display: none;
        visibility: hidden;
      }
    }
    */
  }
  .opened-sidebar {
    padding-left: 0px;
  }
}