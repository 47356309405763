.app-bonus .content {
  width: 1186px;
}
.bonus-page {
  --bonuspage-block-bg: #0E121D;
  --bonuspage-block-border-color: rgba(49, 57, 81, 0.4);
  --bonuspage-block-color-primary: #3B58E7;
  --bonuspage-block-progressbar-bg: #1E273D;
  --bonuspage-block-input-bg: #131927;
  --bonuspage-block-title-color: #FFFFFF;
  --bonuspage-block-text-color: #657099;
  --bonuspage-banner-text-color: #FFFFFF;
  --bonuspage-banner-text-accent-color: #FFC536;
  --bonuspage-list-title-color: #FFFFFF;
  --bonuspage-list-text-color: #5A6477;
  --bonuspage-list-item-border: rgba(79, 88, 106, 0.2);
  padding-top: 36px;
  padding-bottom: 33px;

  .bonus-page-infoblock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;
    gap: 14px;

    .bonus-page-balance {
      background: var(--bonuspage-block-bg);
      border: 2px solid var(--bonuspage-block-border-color);
      border-radius: 32px;
      overflow: hidden;
      flex: 486px 0 0;
      width: 100%;
      position: relative;
      padding: 55px 40px 23px 41px;

      .bonus-page-infoblock-title {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--bonuspage-block-color-primary);
        color: var(--bonuspage-block-title-color);
        font-size: 16px;
        font-weight: 700;
        border-radius: 0px 0px 32px 0px;
        min-height: 40px;
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        color: var(--bonuspage-block-title-color);
        margin-bottom: 17px;

        span {
          color: var(--bonuspage-block-text-color);
        }
      }

      .bonus-page-infoblock-progressbar-wrap {
        margin-bottom: 22px;

        .bonus-page-infoblock-progressbar-desc {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 500;

          .bonus-page-infoblock-progressbar-desc-item {
            display: flex;
            align-items: center;
          }
        }

        .bonus-page-infoblock-progressbar-desc-top {
          color: var(--bonuspage-block-title-color);
          margin-bottom: 6px;

          img {
            margin: 0 4px 0 0;
          }
        }

        .bonus-page-infoblock-progressbar-desc-bottom {
          color: var(--bonuspage-block-text-color);
          font-size: 13px;
          font-weight: 400;
        }

        .bonus-page-infoblock-progressbar {
          width: 100%;
          height: 8px;
          background-color: var(--bonuspage-block-progressbar-bg);
          border-radius: 4px;
          margin-bottom: 7px;
        }
        .bonus-page-infoblock-progressbar-fill {
          background-color: var(--bonuspage-block-color-primary);
          width: 0;
          height: 100%;
          border: 2px solid var(--bonuspage-block-progressbar-bg);
          border-radius: 4px;
        }
      }

      .bonus-page-infoblock-promocode {
        display: flex;
        align-items: center;

        input {
          width: 100%;
          padding: 15px 16px;
          background-color: var(--bonuspage-block-input-bg);
          font-weight: 500;
          font-size: 12px;
          color: var(--bonuspage-block-color-primary);
          border-radius: 10px;
          border: none;
          outline: none;
          margin-right: 12px;

          &::placeholder {
            color: var(--bonuspage-block-text-color);
          }
        }

        button {
          height: 46px;
          margin-right: 1px;
          padding: 0 28px;
        }
      }
    }
  }

  .bonus-page-banner {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 32px;
    position: relative;

    .bonus-page-banner-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 40px;

      h2 {
        color: var(--bonuspage-banner-text-color);
        font-weight: 900;
        line-height: 29px;
        max-width: 320px;
        margin-bottom: 7px;
        text-transform: uppercase;

        span {
          color: var(--bonuspage-banner-text-accent-color);
        }
      }

      p {
        color: var(--bonuspage-banner-text-color);
        font-size: 15px;
        font-weight: 400;
        line-height: 17.4px;
        margin-bottom: 12px;
      }

      .bonus-page-banner-link {
        text-decoration: none;
        min-width: 121px;
        margin-bottom: 2px;
        border-radius: 6px;
      }
    }
  }

  .bonus-page-list {
    background: var(--bonuspage-block-bg);
    border: 2px solid var(--bonuspage-block-border-color);
    border-radius: 32px;
    overflow: hidden;
    padding: 34px 41px 18px 41px;

    .bonus-page-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 23px;

      .bonus-page-item-image {
        display: flex;
        align-items: center;
        border-radius: 16px;
        overflow: hidden;
        flex: 236px 0 0;
        padding-right: 10px;
      }

      .bonus-page-item-content {
        flex: 1 1 auto;
        padding: 1px 90px 0 22px;

        h4 {
          color: var(--bonuspage-list-title-color);
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 7px;
        }

        .bonus-page-item-desc {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          flex-wrap: wrap;
          margin-right: -50px;

          .bonus-page-item-desc-column {
            width: 154px;
            margin-right: 56px;
            margin-bottom: 15px;

            &:first-child {
              width: 180px;
            }

            p {
              color: var(--bonuspage-list-text-color);
              margin-bottom: 2px;

              .bonus-page-item-desc-title {
                color: var(--bonuspage-list-title-color);
              }
            }
          }
        }

        .bonus-page-item-desc-detail {
          border-top: 1px solid var(--bonuspage-list-item-border);
          border-bottom: 1px solid var(--bonuspage-list-item-border);
          padding-top: 18px;
          padding-bottom: 35px;
          margin-right: -25px;
          margin-bottom: 13px;
          display: none;

          .bonus-page-item-desc-column {
            width: 200px;
            margin-right: 13px;
            margin-bottom: 26px;

            &:first-child {
              width: 213px;
            }
          }

          .bonus-page-item-desc-fullwidth {
            color: var(--bonuspage-list-text-color);
            font-size: 15px;
            line-height: 18px;
            padding-right: 30px;
            width: 100%;
          }
        }
      }

      .bonus-page-item-actions {
          padding-top: 14px;

          a {
            border-radius: 6px;
            margin: 0 0 12px;
            min-width: 121px;
            text-decoration: none;
          }
        }
    }

    .bonus-page-item-detailed .bonus-page-item-desc-detail {
      display: flex!important;
    }
  }
}

@media (max-width: 1600px) {
  .opened-chat.opened-sidebar {
    .bonus-page {
      .bonus-page-infoblock {
        flex-direction: column;

        .bonus-page-balance {
          flex: unset;
        }

        .bonus-page-banner {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            min-height: 256px;
            min-width: 686px;
          }
        }
      }

      .bonus-page-list {
        .bonus-page-item {
          flex-wrap: wrap;

          .bonus-page-item-content {
            padding: 30px 0;
            order: 2;
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .opened-chat {
    .bonus-page {
      .bonus-page-infoblock {
        flex-direction: column;

        .bonus-page-balance {
          flex: unset;
        }

        .bonus-page-banner {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            min-height: 256px;
            min-width: 686px;
          }
        }
      }

      .bonus-page-list {
        .bonus-page-item {
          flex-wrap: wrap;

          .bonus-page-item-content {
            padding: 30px 0;
            order: 2;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .opened-sidebar {
    .bonus-page {
      .bonus-page-infoblock {
        flex-direction: column;

        .bonus-page-balance {
          flex: unset;
        }

        .bonus-page-banner {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            min-height: 256px;
            min-width: 686px;
          }
        }
      }

      .bonus-page-list {
        .bonus-page-item {
          flex-wrap: wrap;

          .bonus-page-item-content {
            padding: 30px 0;
            order: 2;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .bonus-page {
    .bonus-page-infoblock {
      flex-direction: column;

      .bonus-page-balance {
        flex: unset;
      }

      .bonus-page-banner {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          min-height: 256px;
          min-width: 686px;
        }
      }
    }

    .bonus-page-list {
      padding: 25px 25px 10px 25px;

      .bonus-page-item {
        flex-wrap: wrap;

        .bonus-page-item-content {
          padding: 30px 0;
          order: 2;
        }
      }
    }
  }
}