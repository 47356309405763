.fancybox__content {
  z-index: 200;
}

.fancybox__backdrop {
  --fancy-box-bg:rgba(0, 0, 0, 0.8);
  background: var(--fancy-box-bg);
  backdrop-filter: blur(2px);
}

.fancybox__content > .f-button.is-close-btn {
  top: 16px;
  right: 16px;
}

.fancybox__slide::before {
  margin: 0 0 5vh;
}
