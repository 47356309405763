.opened-notifications {
  padding-right: 382px;
}
#notifications {
  --notifications-item-bgcolor:#13192F;
  --notifications-text-color-accent:#3B58E7;
  --notifications-text-color:#657099;
  --notifications-text-color-primary:#FFFFFF;
  --notifications-toggler-bg:#131927;
  --notifications-toggler-button-hover:#3B58E7;
  --notifications-toggler-text-color:#657099;
  --notifications-toggler-text-color-active:#FFFFFF;
  --notifications-mark-color:#FFFFFF;
  --notifications-mark-bg-color:#E73B3B;
  --notifications-bg:#0E121D;
  --notifications-title-color:#ffffff;
  --notifications-view-all-bg:#131927;
  --notifications-view-all-border-color:rgba(49, 57, 81, 0.7);
  --notifications-view-all-text-color:#EEECEC;
  display: block;
  position: fixed;
  z-index: 50;
  width: 382px;
  top: 69px;
  right: -382px;
  bottom: 0;
  height: auto;
  transition: all ease-in-out 0.2s;

  &.notifications-showed {
    right: 0;
  }

  .notifications {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 12px 61px 24px;
    background-color: var(--notifications-bg);
  }

  .notifications__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    padding-right: 12px;
  }

  .notifications__head__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    h3 {
      font-weight: 900;
      font-size: 20px;
      text-transform: uppercase;
      color: var(--notifications-title-color);
    }
  }

  .notifications__head__right {
    display: flex;
    align-items: center;

    .dropdown-popup-component {
      border: 0;
      background: transparent;
      margin-right: 33px;

      .view-all {
        gap: 5px;
        font-size: 12px;
        padding: 0 6px;
        border: 1.5px solid var(--notifications-view-all-border-color);
        width: auto;
        height: 35px;

        span {
          font-size: 12px;
        }
      }

      .notifications_viewall {
        min-width: 120px;
      }
    }
  }

  .notifications__toggler {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--notifications-toggler-bg);
    height: 34px;
    flex: 34px 0 0;
    width: calc(100% - 12px);
    border-radius: 10px;
    margin-bottom: 14px;
    overflow: hidden;
    margin-right: 12px;
  }

  .notifications__toggler__button {
    border: none;
    outline: none;
    height: 34px;
    width: auto;
    flex: 1;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--notifications-toggler-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: transparent;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;

    &:hover, &.active {
      background-color: var(--notifications-toggler-button-hover);
      color: var(--notifications-toggler-text-color-active);
    }
  }

  .notifications__body {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    padding: 0 6px 0 0;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: var(--notifications-bg);
    }

    &::-webkit-scrollbar-thumb {
      background: #313951;
      border-radius: 3px;
    }
  }

  .notifications__item {
    background: var(--notifications-item-bgcolor);
    padding: 13px 16px 18px 20px;
    border-radius: 8px;
    margin-top: 3px;
    margin-bottom: 11px;
    width: 334px;

    &.notification-hidden {
      display: none;
    }
  }

  .notifications__item__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px;
  }

  .notifications__item__meta__left {
    color: var(--notifications-text-color);
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
  }

  .notifications__item__meta .notifications__item__meta__mark {
    color: var(--notifications-mark-color);
    background-color: var(--notifications-mark-bg-color);
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 2px 15px 1px;
  }

  .notifications__item__title {
    color: var(--notifications-text-color-primary);
    margin-bottom: 5px;
  }

  .notifications__item__image {
    margin-bottom: 1px;
  }

  .notifications__item__description {
    line-height: 17px;
    margin-bottom: 19px;

    span {
      color: var(--notifications-text-color);
      font-size: 12px;
      line-height: 15px;
    }

    .notifications__item__description__show {
      color: var(--notifications-text-color-primary);
    }
  }

  .notifications__item__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
  }

  .notifications__item__actions__left span {
    color: var(--notifications-text-color-accent);
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
  }

  .notifications__item__actions__right {
    .notifications__item__actions__markread {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    span {
      color: var(--notifications-text-color);
      font-size: 12px;
      font-weight: 600;
      margin-right: 6px;
    }
  }

  .notifications__head__right__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover, &:active {
      outline: none;
    }
  }

  .notifications__footer {
    height: 61px;
    border-top: 1px solid #1f2637;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      color: var(--notifications-text-color);
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      margin-right: 6px;
    }
  }
}
@media (max-width: 1200px) {
  #notifications {
    top: 110px;
  }
}
@media (max-width: 991px) {
  #notifications {
    top: 61px;
    z-index: 100;
  }
}
@media (max-width: 767px) {
  .opened-notifications {
    padding-right: 0;
  }
}