.slider-component {
  --slider-component-text-title-color:#ffffff;
  margin-top: 60px;
}

.slider-component-margin-top {
  margin-top: 47px;
}

.slider-component__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-component__title__text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h2 {
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    color: var(--slider-component-text-title-color);
  }
}

.slider-component__title__controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.slider-component__title__controls__arrows {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .button {
    height: 32px;
    width: 32px;
    border-radius: 8px;

    &:disabled {
      opacity: 0.4;
    }

    &:first-child img {
      transform: rotate(-180deg);
    }
  }
}

.slider-component .splide {
  margin-top: 24px;
}

.splide__slide img {
  max-width: 100%;
  height: auto;
}
