/** metamask email */

.popup-metamask-email {
  --popup-metamask-email-title-color:#ffffff;
  --popup-metamask-email-form-bg:#1E273D;
  --popup-metamask-email-form-border:rgba(49, 57, 81, 0.4);
  --popup-metamask-email-form-title:#3B58E7;
  --popup-metamask-email-form-input-bg:#131927;
  --popup-metamask-email-form-input-text:#F5F6F7;
  --popup-metamask-email-form-input-placeholder:rgba(101, 112, 153, 0.5);
  --popup-metamask-email-form-btn-bg:#3F4654;
  --popup-metamask-email-err:#D32323;
  --popup-metamask-email-success:#57BD26;
  width: 473px;
  height: auto;
}

.popup-metamask-email__context {
  position: relative;
  z-index: 1;
}

.popup-metamask-email__context__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-metamask-email-title-color);
  }
}

.popup-metamask-email__context__content {
  margin-top: 17px;
}

.popup-metamask-email__context__content__form {
  background: var(--popup-metamask-email-form-bg);
  border: 2px solid var(--popup-metamask-email-form-border);
  border-radius: 10px;
  padding: 15px 25px;

  form {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 14px;

    p {
      font-weight: 500;
      font-size: 15px;
      color: var(--popup-metamask-email-form-title);
    }

    input {
      width: 100%;
      background-color: var(--popup-metamask-email-form-input-bg);
      border-radius: 6px;
      padding: 9px 15px;
      font-weight: 600;
      font-size: 14px;
      color: var(--popup-metamask-email-form-input-text);
      border: 2px solid var(--popup-metamask-email-form-input-bg);
      outline: none;

      &::placeholder {
        color: var(--popup-metamask-email-form-input-placeholder);
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-metamask-email-form-input-placeholder);
      }

      &:hover, &:focus {
        outline: none;
      }
    }

    button {
      &:disabled {
        background-color: var(--popup-metamask-email-form-btn-bg);
      }
    }

    &.error {
      p {
        color: var(--popup-metamask-email-err);
      }

      input {
        border-color: var(--popup-metamask-email-err);
        color: var(--popup-metamask-email-err);
      }
    }

    &.success p {
      color: var(--popup-metamask-email-success);
    }
  }
}
