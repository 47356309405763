.popup {
  --popup-bg:#0E121D;
  background-color: var(--popup-bg);
  display: none;
  border-radius: 10px;
  padding: 24px;
  position: relative;

  img.bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/** User Details */
.tab-fiat__select,
.tab-withdraw .tab-crypto__selects__select {
  margin-bottom: 15px;

  label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: var(--popup-wallet-purple);
    margin-bottom: 6px;
  }
}
.popup-user-details {
  --popup-user-details-title-color:#ffffff;
  --popup-user-details-user-text-color:#F5F6F7;
  --popup-user-details-user-subtext-color:#657099;
  --popup-user-details-user-status-bg:#131927;
  --popup-user-details-details-btn-color:#3B58E7;
  --popup-user-details-label-color:#35210E;
  --popup-user-details-freeze:#46D3FF;
  --popup-user-details-fire:#FF8946;
  --popup-user-details-cash:#6FC820;
  width: 473px;
  height: auto;
}

.popup-user-details__context {
  position: relative;
  z-index: 1;
}

.popup-user-details__context__header h5 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--popup-user-details-title-color);
}

.popup-user-details__context__content {
  margin-top: 27px;
}

.popup-user-details__context__content__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
}

.popup-user-details__context__content__info__avatar {
  height: 97px;
  width: 97px;
  position: relative;

  img {
    &.user {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &.online {
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }
}

.popup-user-details__context__content__info__text {
  h6 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    span {
      font-weight: 700;
      font-size: 24px;
      color: var(--popup-user-details-user-text-color);
    }
  }

  p {
    &.id {
      font-weight: 400;
      font-size: 14px;
      color: var(--popup-user-details-user-subtext-color);
    }

    &.status {
      margin-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--popup-user-details-user-status-bg);
      border-radius: 50px;
      padding: 10px 15px;
      font-weight: 500;
      font-size: 12px;
      width: fit-content;
      color: var(--popup-user-details-title-color);
    }
  }
}

.popup-user-details__context__content__blocks {
  margin-top: 16px;
}

.popup-user-details__context__content__blocks__block {
  padding: 16px;
  margin-top: 8px;
  background: #1E273D;
  border: 2px solid rgba(49, 57, 81, 0.4);
  border-radius: 10px;
}

.popup-user-details__context__content__blocks__block__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-user-details__context__content__blocks__block__header__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: var(--popup-user-details-user-text-color);
  }
}

.popup-user-details__context__content__blocks__block__header a {
  font-weight: 500;
  font-size: 14px;
  color: var(--popup-user-details-details-btn-color);
  text-decoration: none;

  &:hover, &:focus, &:active, &:visited {
    color: var(--popup-user-details-details-btn-color);
    text-decoration: none;
  }
}

.popup-user-details__context__content__blocks__block__body {
  margin-top: 16px;
}

.popup-user-details__context__content__blocks__block__body__achivements {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  height: 63px;
}

.popup-user-details__context__content__blocks__block__body__achivements__achive {
  height: 63px;
  width: 50px;
  position: relative;
}

.popup-user-details__context__content__blocks__block__body__achivements__achive__non_achive {
  display: none;
  visibility: hidden;
  height: 63px;
  width: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  position: absolute;
  top: 0;
  left: 0;

  &.active {
    display: block;
    visibility: visible;
  }
}

.popup-user-details__context__content__blocks__block__body__achivements__achive {
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &.crown {
    height: 60px;
    width: 60px;
  }
}

.popup-user-details__context__content__blocks__block__body__statistics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.popup-user-details__context__content__blocks__block__body__statistics__block {
  flex: 1;
  background-color: var(--popup-user-details-user-status-bg);
  border-radius: 10px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-user-details__context__content__blocks__block__body__statistics__block__context p {
  font-weight: 600;
  font-size: 13px;
  color: var(--popup-user-details-user-subtext-color);
  text-align: center;
}

.popup-user-details__context__content__blocks__block__body__statistics__block__context__info {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    font-weight: 600;
    font-size: 16px;
    color: var(--popup-user-details-user-text-color);
  }
}

.popup-user-details__context__content__blocks__block__body__favorites {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 9px;

  a {
    display: block;
    border-radius: 14px;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    max-width: 128px;
    height: 157px;

    img.f-bg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .label {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      padding: 6px 8px;
      border-bottom-right-radius: 16px;

      span {
        font-weight: 700;
        font-size: 12px;
        color: var(--popup-user-details-label-color);
      }

      &.cash {
        background-color: var(--popup-user-details-cash);
      }

      &.fire {
        background-color: var(--popup-user-details-fire);
      }

      &.freeze {
        background-color: var(--popup-user-details-freeze);
      }
    }

    &.cash {
      border: 2px solid var(--popup-user-details-cash);
    }

    &.fire {
      border: 2px solid var(--popup-user-details-fire);
    }

    &.freeze {
      border: 2px solid var(--popup-user-details-freeze);
    }
  }
}

/** User Details Inner */

.popup-user-details-inner {
  --popup-user-details-inner-title-color:#ffffff;
  --popup-user-details-inner-user-text-color:#F5F6F7;
  --popup-user-details-inner-user-status-bg:#131927;
  --popup-user-details-inner-user-subtext-color:#657099;
  --popup-user-details-inner-blue:#3B58E7;
  width: 473px;
  height: auto;
}

.popup-user-details-inner__context {
  position: relative;
  z-index: 1;
}

.popup-user-details-inner__context__header h5 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--popup-user-details-inner-title-color);
}

.popup-user-details-inner__context__content {
  margin-top: 27px;
}

.popup-user-details-inner__context__content__avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.popup-user-details-inner__context__content__avatar__info h6 {
  font-weight: 700;
  font-size: 20px;
  color: var(--popup-user-details-inner-title-color);
}

.popup-user-details-inner__context__content__avatar__info__awards {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.popup-user-details-inner__context__content__blocks {
  margin-top: 23px;
}

.popup-user-details-inner__context__content__blocks__block {
  padding: 16px;
  margin-top: 8px;
  background: #1E273D;
  border: 2px solid rgba(49, 57, 81, 0.4);
  border-radius: 10px;
}

.popup-user-details-inner__context__content__blocks__block__body__statistics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.popup-user-details-inner__context__content__blocks__block__body__statistics__block {
  flex: 1;
  background-color: var(--popup-user-details-inner-user-status-bg);
  border-radius: 10px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-user-details-inner__context__content__blocks__block__body__statistics__block__context p {
  font-weight: 600;
  font-size: 13px;
  color: var(--popup-user-details-inner-user-subtext-color);
  text-align: center;
}

.popup-user-details-inner__context__content__blocks__block__body__statistics__block__context__info {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    font-weight: 600;
    font-size: 16px;
    color: var(--popup-user-details-inner-user-text-color);
  }
}

.popup-user-details-inner__context__content__table_stat {
  padding: 16px;
  margin-top: 8px;
  background: #1E273D;
  border: 2px solid rgba(49, 57, 81, 0.4);
  border-radius: 10px;

  table.table {
    thead tr th, tbody tr td div {
      padding: 10px 16px;
    }
  }

  a {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    color: var(--popup-user-details-inner-blue);
  }
}

/** Promo */

.popup-promo-code {
  --popup-promo-code-title-color:#ffffff;
  --popup-promo-code-form-bg:#1E273D;
  --popup-promo-code-form-border:rgba(49, 57, 81, 0.4);
  --popup-promo-code-form-title:#3B58E7;
  --popup-promo-code-form-input-bg:#131927;
  --popup-promo-code-form-input-text:#F5F6F7;
  --popup-promo-code-form-input-placeholder:rgba(101, 112, 153, 0.5);
  --popup-promo-code-form-btn-bg:#3F4654;
  --popup-promo-code-err:#D32323;
  --popup-promo-code-success:#57BD26;
  width: 473px;
  height: auto;
}

.popup-promo-code__context {
  position: relative;
  z-index: 1;
}

.popup-promo-code__context__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-promo-code-title-color);
  }
}

.popup-promo-code__context__content {
  margin-top: 17px;
}

.popup-promo-code__context__content__form {
  background: var(--popup-promo-code-form-bg);
  border: 2px solid var(--popup-promo-code-form-border);
  border-radius: 10px;
  padding: 15px 25px;

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 15px;
      color: var(--popup-promo-code-form-title);
    }

    input {
      margin-top: 8px;
      width: 100%;
      background-color: var(--popup-promo-code-form-input-bg);
      border-radius: 6px;
      padding: 9px 15px;
      font-weight: 600;
      font-size: 14px;
      color: var(--popup-promo-code-form-input-text);
      border: 2px solid var(--popup-promo-code-form-input-bg);
      outline: none;

      &::placeholder {
        color: var(--popup-promo-code-form-input-placeholder);
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-promo-code-form-input-placeholder);
      }

      &:hover, &:focus {
        outline: none;
      }
    }

    button {
      margin-top: 27px;

      &:disabled {
        background-color: var(--popup-promo-code-form-btn-bg);
      }
    }

    &.error {
      p {
        color: var(--popup-promo-code-err);
      }

      input {
        border-color: var(--popup-promo-code-err);
        color: var(--popup-promo-code-err);
      }
    }

    &.success p {
      color: var(--popup-promo-code-success);
    }
  }
}

/** Cashback */

.popup-cashback {
  --popup-cashback-title-color:#ffffff;
  --popup-cashback-info-p:#657099;
  --popup-cashback-info-p-span:#F5F6F7;
  --popup-cashback-info-a:#3B58E7;
  --popup-cashback-btn-bg:#3F4654;
  width: 473px;
  height: auto;

  img {
    &.lights {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.rabbit {
      position: absolute;
      top: 30px;
      left: 0;
      z-index: 1;
    }
  }
}

.popup-cashback__context {
  position: relative;
  z-index: 1;
}

.popup-cashback__context__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-cashback-title-color);
  }
}

.popup-cashback__context__content__award {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    top: -45px;
    right: -40px;
  }
}

.popup-cashback__context__content__award__info {
  margin-top: 170px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  p {
    &:nth-child(1) {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-cashback-info-p);

      span {
        color: var(--popup-cashback-info-p-span);
      }
    }

    &:nth-child(2) {
      font-weight: 600;
      font-size: 24px;
      color: var(--popup-cashback-info-p-span);
    }
  }

  a {
    font-weight: 500;
    font-size: 14px;
    color: var(--popup-cashback-info-a);
  }
}

.popup-cashback__context__content__statistics {
  margin-top: 8px;
  position: relative;
  overflow: hidden;
  z-index: 50;
  padding: 38px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 437px;
  height: auto;
  border: 1.5px solid rgba(48, 57, 86, 0.5);
  border-radius: 16px;

  img.bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.popup-cashback__context__content__statistics__context {
  width: 100%;
  position: relative;
  z-index: 1;
}

.popup-cashback__context__content__statistics__context__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  p {
    &:first-child {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-cashback-info-p-span);
    }

    &:last-child {
      font-weight: 700;
      font-size: 36px;
      color: var(--popup-cashback-info-p-span);
    }
  }
}

.popup-cashback__context__content__statistics__context__blocks {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.popup-cashback__context__content__statistics__context__blocks__block {
  flex: 1;
  padding: 0 16px;

  &:not(:last-child) {
    border-right: 1px solid rgba(101, 112, 153, 0.2);
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: var(--popup-cashback-info-p);
    text-align: center;

    &.fat {
      font-weight: 700;
      font-size: 16px;
      color: var(--popup-cashback-info-a);
    }
  }

  div {
    margin-top: 9px;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    gap: 4px;
  }
}

.popup-cashback__context__content__statistics__context__accrual {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    &.accrual_title {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-cashback-info-p-span);
    }

    &.accrual_date {
      font-weight: 700;
      font-size: 24px;
      color: var(--popup-cashback-info-a);
    }
  }

  .button {
    margin-top: 16px;
    height: 40px;
    width: 108px;

    &:disabled {
      background-color: var(--popup-cashback-btn-bg);
    }
  }
}

/** Wheel */
.popup-wheel {
  --popup-wheel-title-color: #ffffff;
  --popup-wheel-info-bg: #1E273D;
  --popup-wheel-info-border: rgba(49, 57, 81, 0.4);
  --popup-wheel-info-block-bg: #131927;
  --popup-wheel-info-block-text-color: #657099;
  --popup-wheel-info-block-text-color-blue: #3B58E7;

  width: 473px;
  height: auto;

  &__context {
    position: relative;
    z-index: 1;

    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      h5 {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        color: var(--popup-wheel-title-color);
      }
    }

    &__content {
      &__spin {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__info {
        margin-top: 32px;
        background: var(--popup-wheel-info-bg);
        border: 2px solid var(--popup-wheel-info-border);
        border-radius: 12px;
        padding: 8px;

        &__context {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 8px;

          &__block {
            flex: 1;
            background-color: var(--popup-wheel-info-block-bg);
            border-radius: 10px;
            min-height: 80px;

            &:first-child {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              p {
                &:first-child {
                  font-weight: 500;
                  font-size: 13px;
                  color: var(--popup-wheel-info-block-text-color);
                }

                &:last-child {
                  font-weight: 600;
                  font-size: 20px;
                  color: var(--popup-wheel-info-block-text-color-blue);
                }
              }
            }

            &:last-child {
              display: flex;
              justify-content: center;
              align-items: center;

              .wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;

                div {
                  display: flex;
                  flex-direction: column;
                  gap: 3px;

                  p {
                    font-weight: 500;
                    font-size: 15px;
                    color: var(--popup-wheel-info-block-text-color);

                    span {
                      text-transform: uppercase;

                      &.blue {
                        color: var(--popup-wheel-info-block-text-color-blue);
                      }
                      &.white {
                        color: var(--popup-wheel-title-color);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/** Auth */

.popup-auth {
  --popup-auth-label:#000000;
  --popup-auth-label-bg:#E3A70C;
  --popup-auth-h1:#FFFFFF;
  --popup-auth-subtitle:rgba(255, 255, 255, 0.7);
  --popup-auth-h1-span:#FFDF39;
  --popup-auth-purple:#657099;
  --popup-auth-input-bg:#131927;
  --popup-auth-blue:#3B58E7;
  padding: 0;
  position: relative;
  border-radius: 20px;
  max-height: calc(100vh - 60px);
  overflow: hidden;


  ::-webkit-scrollbar {
    border-radius: 3px;
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--sidebar-bg);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--sidebar-bg);
  }

  &:hover ::-webkit-scrollbar-thumb {
    background: #313951;
  }

  .f-button.is-close-btn {
    background: transparent;
    border: none;
    outline: none;
  }
}

.popup-auth__context {
  display: none;
  justify-content: flex-start;
  width: 770px;
  max-width: 100%;
  height: 100%;

  .popup-auth__context__content__tabs {
    display: flex;
    gap: 20px;

    .button:not(.button__blue) {
      background: transparent;
      box-shadow: none;
      opacity: .7;
      padding: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.popup-auth__context.active {
  display: flex;
}

.popup-auth__context__banner {
  width: 375px;
  height: 738px;
  max-height: 100%;
  position: relative;

  .popup-auth__context__banner-image {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  img .bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }

}
.popup-auth__context__banner__info__text {
  box-shadow: 0px 0px 20px 21px #0000004D;
  background: #0000004D;
}
.popup-auth__context__banner__info {
  margin-top: 80px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-auth__context__banner__info__label {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--popup-auth-label);
  background: var(--popup-auth-label-bg);
  border-radius: 100px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-auth__context__banner__info__title {
  font-weight: 700;
  font-size: 34px;
  text-align: center;
  text-transform: uppercase;

  span {
    font-weight: 700;
    font-size: 34px;
    text-align: center;
    text-transform: uppercase;
    color: var(--popup-auth-h1-span);
  }

  color: var(--popup-auth-h1);
  margin-top: 20px;
  margin-bottom: 0;
}

.popup-auth__context__banner__info__subtitle {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: 18px;
  color: var(--popup-auth-subtitle);
}

.popup-auth__context__content {
  flex: 1;
  padding: 24px;
  position: relative;
  overflow: hidden auto;

  .shadow {
    position: absolute;
    top: -280px;
    left: -6%;
    margin: 0 auto;
    height: 100%;
    width: 112%;
    object-fit: contain;
    z-index: -1;
  }
}

.popup-auth__context__content__title {
  font-weight: 700;
  font-size: 24px;
  color: var(--popup-auth-h1);
  text-align: center;
}

.popup-auth__context__content__form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 19px;
}

.popup-auth__context__content__form__form_control {
  label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: var(--popup-auth-purple);
  }

  input {
    margin-top: 8px;
    width: 100%;
    padding: 17px 14px;
    background-color: var(--popup-auth-input-bg);
    font-weight: 400;
    font-size: 14px;
    color: var(--popup-auth-h1);
    border-radius: 6px;
    border: none;
    outline: none;

    &::placeholder {
      color: var(--popup-auth-purple);
      opacity: 1;
    }

    &:-ms-input-placeholder, &::-ms-input-placeholder {
      color: var(--popup-auth-purple);
    }

    &:hover, &:focus {
      outline: none;
    }
  }

  .dropdown-popup-component {
    margin-top: 8px;
    border: 0;

    .currency-picker {
      width: 100%;
      height: auto;
      padding: 17px 14px;
      background-color: var(--popup-auth-input-bg);
      font-weight: 400;
      font-size: 14px;
      color: var(--popup-auth-h1);
      border-radius: 6px;
      border: none;
      outline: none;
    }

    .currency-select {
      .dropdown-popup__select {
        &.active,
        &:hover {
          span {
            color: var(--popup-auth-h1);
          }
        }
      }
    }
  }

  &.form_control_password {
    position: relative;

    input {
      padding-right: 40px;
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      position: absolute;
      top: 45px;
      right: 17px;
      height: fit-content;
      width: fit-content;
      cursor: pointer;
    }
  }
}

.popup-auth__context__content__form__link {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  a {
    font-weight: 500;
    font-size: 14px;
    color: var(--popup-auth-blue);
  }
}

.popup-auth__context__content__form__checkbox label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: var(--popup-auth-input-bg);
    border-radius: 5px;

    &:checked {
      background-color: var(--popup-auth-blue);
      color: var(--popup-auth-h1);
      font-size: 20px;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: var(--popup-auth-purple);

    a {
      font-weight: 400;
      font-size: 12px;
      text-decoration: none;
      color: var(--popup-auth-blue);
    }
  }
}

.popup-auth__context__content__form .button {
  width: 100%;
  min-height: 47px;
}

.popup-auth__context__content__auth_other {
  margin-top: 36px;

  p {
    font-weight: 500;
    font-size: 16px;
    color: var(--popup-auth-h1);
    text-align: center;

    a {
      color: var(--popup-auth-blue);
    }
  }
}

.popup-auth__context__content__auth_other__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 11px;
  margin-bottom: 26px;
}

@media (max-width: 767px) {
  .popup-auth__context__banner {
    display: none;
  }
}

/** Wallet */

.popup-wallet {
  --popup-wallet-title-color:#ffffff;
  --popup-wallet-purple:#657099;
  --popup-wallet-blue:#3B58E7;
  --popup-wallet-black:#131927;
  --popup-wallet-white-light:#F5F6F7;
  --popup-wallet-gold:#DFA100;
  --popup-wallet-black-blue:#1E273D;
  --popup-wallet-green:#57BD26;
  width: 473px;
  height: auto;
  max-height: calc(100vh - 60px);

  .tabs-toggle {
    background: var(--popup-wallet-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .tabs-toggle-button, .tabs-toggle-inner-button {
      flex: 1;
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 6px;
      transition: color ease-in-out .2s;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }

    .tabs-toggle-button img, .tabs-toggle-inner-button img {
      margin: 0 auto;
      transition: color ease-in-out .2s;
    }

    .tabs-toggle-button span, .tabs-toggle-inner-button span {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-wallet-purple);
      text-align: center;
      transition: color ease-in-out .2s;
    }

    .tabs-toggle-button {
      &.active, &:hover {
        background-color: var(--popup-wallet-blue);
      }
    }

    .tabs-toggle-inner-button {
      &.active, &:hover {
        background-color: var(--popup-wallet-blue);
      }
    }

    .tabs-toggle-button {
      &.active *, &:hover * {
        color: var(--popup-wallet-title-color);
      }
    }

    .tabs-toggle-inner-button {
      &.active *, &:hover * {
        color: var(--popup-wallet-title-color);
      }
    }

    .tabs-toggle-button {
      &.active img, &:hover img {
        filter: brightness(0) invert(1);
      }
    }

    .tabs-toggle-inner-button {
      &.active img, &:hover img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .tabs-content {
    margin-top: 8px;
    background: var(--popup-wallet-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 10px;
    padding: 24px 25px;

    .tabs-content-tab, .tabs-content-tab-inner {
      display: none;
      visibility: hidden;
    }

    .tabs-content-tab.active, .tabs-content-tab-inner.active {
      display: block;
      visibility: visible;
    }
  }
}

.popup-wallet__context {
  position: relative;
  z-index: 1;
  max-height: 100%;
  overflow: hidden;
}
.popup-wallet__context__content__tabs {

  ::-webkit-scrollbar {
    border-radius: 3px;
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--sidebar-bg);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--sidebar-bg);
  }

  &:hover ::-webkit-scrollbar-thumb {
    background: #313951;
  }
}
.popup-wallet__context__content__tabs>.tabs-content {
  overflow: auto;
  max-height: calc(100vh - 252px);
}
.popup-wallet__context__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-wallet-title-color);
  }

  button {
    width: fit-content;
    height: auto;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-right: 55px;

    &:hover, &:active {
      outline: none;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-wallet-purple);
    }
  }
}

.popup-wallet__context__content {
  margin-top: 30px;
}

.popup-wallet__context__content__tabs {
  .dropdown-popup-component {
    border: none;
  }

  .tabs-toggle-inner-button {
    height: 32px;
  }

  .dropdown-popup-toggler {
    height: 48px;
  }

  .dropdown-popup {
    top: 110%;
    border: none;
  }

  .tab-deposit .tabs-toggle, .tab-withdraw .tabs-toggle, .tab-buy-crypto .tabs-toggle, .tab-invest .tabs-toggle {
    background-color: var(--popup-wallet-black);
  }

  .tab-deposit .tabs-content, .tab-withdraw .tabs-content, .tab-buy-crypto .tabs-content, .tab-invest .tabs-content {
    border: none;
    padding: 0;
    margin-top: 16px;
  }

  .tab-crypto__input-amount {
    position: relative;
    width: 100%;
    margin-top: 16px;

    label {
      display: block;
      color: var(--popup-wallet-purple);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    input {
      background: var(--popup-wallet-black);
      border-radius: 10px;
      padding: 11px 16px;
      padding-right: 35px;
      font-weight: 500;
      font-size: 12px;
      color: var(--popup-wallet-white-light);
      border: none;
      outline: none;
      width: 100%;
      height: 48px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
      }

      &:hover, &:focus, &:active {
        outline: none;
      }

      &::placeholder {
        color: var(--popup-wallet-purple);
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-wallet-purple);
      }
    }

    button {
      height: 46px;
    }
  }

  .tab-deposit {
    .tab-crypto label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-wallet-purple);
      margin-bottom: 6px;
    }

    .tab-crypto__selects {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
    }

    .tab-crypto__selects__select {
      flex: 1;

      .dropdown {
        width: 100%;
        height: 48px;

        .dropdown-toggle {
          justify-content: space-between;
          width: 100%;
          height: 48px;
          border-radius: 10px;

          .title img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .tab-crypto__input {
      position: relative;
      width: 100%;
      margin-top: 16px;

      input {
        background: var(--popup-wallet-black);
        border-radius: 10px;
        padding: 11px 16px;
        padding-right: 35px;
        font-weight: 500;
        font-size: 12px;
        color: var(--popup-wallet-white-light);
        border: none;
        outline: none;
        width: 100%;
        height: 48px;

        &:hover, &:focus, &:active {
          outline: none;
        }

        &::placeholder {
          color: var(--popup-wallet-purple);
          opacity: 1;
        }

        &:-ms-input-placeholder, &::-ms-input-placeholder {
          color: var(--popup-wallet-purple);
        }
      }

      button {
        border: none;
        outline: none;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        position: absolute;
        top: 40px;
        right: 16px;
      }
    }


    .tab-crypto__bonus {
      margin-top: 19px;
    }

    .tab-crypto__bonus__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 14px;
        color: var(--popup-wallet-gold);
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-blue);
      }
    }

    .tab-crypto__bonus__content {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 7px;
    }

    .tab-crypto__bonus__content__block {
      flex: 1;
      outline: none;
      border: none;
      cursor: pointer;
      background: var(--popup-wallet-black);
      border-radius: 4px;
      padding: 6px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: color ease-in-out .2s;

      &:nth-child(1), &:nth-child(2) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(1)::before {
        content: '';
        position: absolute;
        right: -23px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 23.5px solid var(--popup-wallet-black);
        border-top: 23.5px solid transparent;
        border-bottom: 23.5px solid transparent;
        z-index: 1;
      }

      &:nth-child(2) {
        &::before {
          content: '';
          position: absolute;
          right: -23px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 23.5px solid var(--popup-wallet-black);
          border-top: 23.5px solid transparent;
          border-bottom: 23.5px solid transparent;
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 23.5px solid var(--popup-wallet-black-blue);
          border-top: 23.5px solid transparent;
          border-bottom: 23.5px solid transparent;
        }
      }

      &:nth-child(3)::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 23.5px solid var(--popup-wallet-black-blue);
        border-top: 23.5px solid transparent;
        border-bottom: 23.5px solid transparent;
      }

      &:nth-child(2) p, &:nth-child(3) p {
        padding-left: 15px;
      }

      &:nth-child(2) img {
        right: -15px;
      }

      p {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-white-light);
        transition: color ease-in-out .2s;
        position: relative;
        z-index: 5;

        span {
          color: var(--popup-wallet-gold);
          transition: color ease-in-out .2s;
        }
      }

      img {
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      &:hover {
        background-color: var(--popup-wallet-blue);

        &::before {
          border-left-color: var(--popup-wallet-blue);
        }

        * {
          color: var(--popup-wallet-title-color);
        }
      }
    }

    .tab-fiat__select {
      flex: 1;

      label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-purple);
        margin-bottom: 6px;
      }

      .dropdown {
        width: 100%;
        height: 48px;

        .dropdown-toggle {
          justify-content: space-between;
          width: 100%;
          height: 48px;
          border-radius: 10px;

          .title img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .tab-fiat__payment {
      margin-top: 16px;

      label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-purple);
        margin-bottom: 6px;
      }

      a {
        display: block;
        text-decoration: none;
        width: fit-content;
        margin: 0 auto;
        margin-top: 12px;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-blue);
      }
    }

    .tab-fiat__payment__list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      flex-wrap: wrap;
    }

    .tab-fiat__payment__list__method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: var(--popup-wallet-black);
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      width: 181px;
      height: 80px;
      padding: 12px 0;

      img {
        margin: 0 auto;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: var(--popup-wallet-purple);
      }
    }

    .tab-fiat__bonus {
      margin-top: 19px;
    }

    .tab-fiat__bonus__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 14px;
        color: var(--popup-wallet-gold);
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-blue);
      }
    }

    .tab-fiat__bonus__content {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 7px;
    }

    .tab-fiat__bonus__content__block {
      flex: 1;
      outline: none;
      border: none;
      cursor: pointer;
      background: var(--popup-wallet-black);
      border-radius: 4px;
      padding: 6px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: color ease-in-out .2s;

      &:nth-child(1), &:nth-child(2) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(1)::before {
        content: '';
        position: absolute;
        right: -23px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 23.5px solid var(--popup-wallet-black);
        border-top: 23.5px solid transparent;
        border-bottom: 23.5px solid transparent;
        z-index: 1;
      }

      &:nth-child(2) {
        &::before {
          content: '';
          position: absolute;
          right: -23px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 23.5px solid var(--popup-wallet-black);
          border-top: 23.5px solid transparent;
          border-bottom: 23.5px solid transparent;
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 23.5px solid var(--popup-wallet-black-blue);
          border-top: 23.5px solid transparent;
          border-bottom: 23.5px solid transparent;
        }
      }

      &:nth-child(3)::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 23.5px solid var(--popup-wallet-black-blue);
        border-top: 23.5px solid transparent;
        border-bottom: 23.5px solid transparent;
      }

      &:nth-child(2) p, &:nth-child(3) p {
        padding-left: 15px;
      }

      &:nth-child(2) img {
        right: -15px;
      }

      p {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-wallet-white-light);
        transition: color ease-in-out .2s;
        position: relative;
        z-index: 5;

        span {
          color: var(--popup-wallet-gold);
          transition: color ease-in-out .2s;
        }
      }

      img {
        position: absolute;
        z-index: 10;
        bottom: 0;
        right: 0;
        z-index: 1;
      }

      &:hover {
        background-color: var(--popup-wallet-blue);

        &::before {
          border-left-color: var(--popup-wallet-blue);
        }

        * {
          color: var(--popup-wallet-title-color);
        }
      }
    }
  }

  .tab-withdraw {
    .tab-crypto__select, .tab-fiat__select {
      flex: 1;
    }

    .tab-crypto__select .dropdown, .tab-fiat__select .dropdown {
      width: 100%;
      height: 48px;
    }

    .tab-crypto__select .dropdown .dropdown-toggle, .tab-fiat__select .dropdown .dropdown-toggle {
      justify-content: space-between;
      width: 100%;
      height: 48px;
      border-radius: 10px;
    }

    .tab-crypto__select .dropdown .dropdown-toggle .title img, .tab-fiat__select .dropdown .dropdown-toggle .title img {
      height: 18px;
      width: 18px;
    }

    .tab-crypto__input, .tab-fiat__input {
      position: relative;
      width: 100%;
      margin-top: 16px;
    }

    .tab-crypto__input label, .tab-fiat__input label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-wallet-purple);
      margin-bottom: 6px;
    }

    .tab-crypto__input input, .tab-fiat__input input {
      background: var(--popup-wallet-black);
      border-radius: 10px;
      padding: 11px 16px;
      padding-right: 67px;
      font-weight: 500;
      font-size: 12px;
      color: var(--popup-wallet-white-light);
      border: none;
      outline: none;
      width: 100%;
      height: 48px;
    }

    .tab-crypto__input input {
      &:hover, &:focus, &:active {
        outline: none;
      }
    }

    .tab-fiat__input input {
      &:hover, &:focus, &:active {
        outline: none;
      }
    }

    .tab-crypto__input input::placeholder, .tab-fiat__input input::placeholder {
      color: var(--popup-wallet-purple);
      opacity: 1;
    }

    .tab-crypto__input input {
      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-wallet-purple);
      }
    }

    .tab-fiat__input input {
      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-wallet-purple);
      }
    }

    .tab-crypto__input button, .tab-fiat__input button {
      border: none;
      outline: none;
      cursor: pointer;
      width: fit-content;
      height: fit-content;
      background-color: var(--popup-wallet-black-blue);
      color: var(--popup-wallet-white-light);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      padding: 5px 15px;
      top: 35px;
      right: 16px;
    }

    .tab-crypto__action, .tab-fiat__action {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .tab-crypto__action p, .tab-fiat__action p {
      font-weight: 500;
      font-size: 12px;
      color: var(--popup-wallet-white-light);
    }

    .tab-crypto__action p span, .tab-fiat__action p span {
      font-weight: 500;
      font-size: 12px;
      color: var(--popup-wallet-green);
    }
  }

  .tab-buy-crypto__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
  }

  .tab-buy-crypto__inputs__input {
    flex: 1;
    width: 100%;

    input {
      background: var(--popup-wallet-black);
      border-radius: 10px;
      padding: 11px 16px;
      padding-right: 67px;
      font-weight: 500;
      font-size: 12px;
      color: var(--popup-wallet-white-light);
      border: none;
      outline: none;
      width: 100%;
      height: 48px;

      &:hover, &:focus, &:active {
        outline: none;
      }

      &::placeholder {
        color: var(--popup-wallet-purple);
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-wallet-purple);
      }
    }
  }

  .tab-buy-crypto__select {
    flex: 1;
    margin-top: 8px;

    .dropdown {
      width: 100%;
      height: 48px;

      .dropdown-toggle {
        justify-content: space-between;
        width: 100%;
        height: 48px;
        border-radius: 10px;

        .subinfo p {
          text-align: left;
        }

        .title img {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .tab-buy-crypto__confirm {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/** Transactions */

.popup-transactions {
  --popup-transactions-title-color:#ffffff;
  --popup-transactions-purple:#657099;
  --popup-transactions-blue:#3B58E7;
  --popup-transactions-black:#131927;
  --popup-transactions-white-light:#F5F6F7;
  --popup-transactions-gold:#DFA100;
  --popup-transactions-black-blue:#1E273D;

  /* override */
  width: 473px;
  height: auto;

  .splide__arrows {
    display: none !important;
    visibility: hidden !important;
  }

  .dropdown-popup-component {
    border: none;
    background: transparent;

    .dropdown-popup-toggler {
      background-color: transparent;
    }

    .dropdown-popup {
      border: none;
    }
  }

  .tabs-toggle {
    background: var(--popup-transactions-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .tabs-toggle-button, .tabs-toggle-inner-button {
      flex: 1;
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 6px;
      transition: color ease-in-out .2s;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }

    .tabs-toggle-button img, .tabs-toggle-inner-button img {
      margin: 0 auto;
      transition: color ease-in-out .2s;
    }

    .tabs-toggle-button span, .tabs-toggle-inner-button span {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-transactions-purple);
      text-align: center;
      transition: color ease-in-out .2s;
    }

    .tabs-toggle-button {
      &.active, &:hover {
        background-color: var(--popup-transactions-blue);
      }
    }

    .tabs-toggle-inner-button {
      &.active, &:hover {
        background-color: var(--popup-transactions-blue);
      }
    }

    .tabs-toggle-button {
      &.active *, &:hover * {
        color: var(--popup-transactions-title-color);
      }
    }

    .tabs-toggle-inner-button {
      &.active *, &:hover * {
        color: var(--popup-transactions-title-color);
      }
    }

    .tabs-toggle-button {
      &.active img, &:hover img {
        filter: brightness(0) invert(1);
      }
    }

    .tabs-toggle-inner-button {
      &.active img, &:hover img {
        filter: brightness(0) invert(1);
      }
    }
  }

  .tabs-content {
    margin-top: 8px;
    background: var(--popup-transactions-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 10px;
    padding: 16px 8px 8px 8px;

    .tabs-content-tab, .tabs-content-tab-inner {
      display: none;
      visibility: hidden;
    }

    .tabs-content-tab.active, .tabs-content-tab-inner.active {
      display: block;
      visibility: visible;
    }
  }
}

.popup-transactions__context {
  position: relative;
  z-index: 1;
}

.popup-transactions__context__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-transactions-title-color);
  }

  button {
    width: fit-content;
    height: auto;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-right: 55px;

    &:hover, &:active {
      outline: none;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: var(--popup-transactions-purple);
    }
  }
}

.popup-transactions__context__content {
  margin-top: 30px;
}

.popup-transactions__context__content__tabs {
  .tab-deposit__slider {
    margin-top: 8px;
    background: var(--popup-transactions-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 10px;
  }

  .tab-deposit__controls {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-deposit__controls__select .dropdown {
    background-color: transparent;
    border: none;

    .dropdown-toggle {
      padding: 0;
      width: fit-content;
      background-color: transparent;
      border: none;
    }
  }

  .tab-deposit__controls__slider_controls {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .button {
      height: 32px;
      width: 32px;

      &:first-child img {
        transform: rotate(-180deg);
      }
    }
  }
}

/* Currency */

.popup-currency {
  --popup-currency-white:#ffffff;
  --popup-currency-white-light:#F5F6F7;
  --popup-currency-black-blue:#1E273D;
  --popup-currency-purple:#657099;
  --popup-currency-blue:#3B58E7;
  --popup-currency-black:#131927;
  width: 473px;
  height: auto;

  .tabs-content {
    margin-top: 8px;
    background-color: var(--popup-currency-black-blue);
    border: 2px solid rgba(49, 57, 81, 0.4);
    border-radius: 10px;
    padding: 16px 24px;

    .tabs-toggle {
      background: var(--popup-currency-black);
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .tabs-toggle-button, .tabs-toggle-inner-button {
        flex: 1;
        padding: 12px 0;
        height: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 6px;
        transition: color ease-in-out .2s;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
      }

      .tabs-toggle-button img, .tabs-toggle-inner-button img {
        margin: 0 auto;
        transition: color ease-in-out .2s;
      }

      .tabs-toggle-button span, .tabs-toggle-inner-button span {
        font-weight: 500;
        font-size: 14px;
        color: var(--popup-currency-purple);
        text-align: center;
        transition: color ease-in-out .2s;
      }

      .tabs-toggle-button {
        &.active, &:hover {
          background-color: var(--popup-currency-blue);
        }
      }

      .tabs-toggle-inner-button {
        &.active, &:hover {
          background-color: var(--popup-currency-blue);
        }
      }

      .tabs-toggle-button {
        &.active *, &:hover * {
          color: var(--popup-currency-white);
        }
      }

      .tabs-toggle-inner-button {
        &.active *, &:hover * {
          color: var(--popup-currency-white);
        }
      }

      .tabs-toggle-button {
        &.active img, &:hover img {
          filter: brightness(0) invert(1);
        }
      }

      .tabs-toggle-inner-button {
        &.active img, &:hover img {
          filter: brightness(0) invert(1);
        }
      }
    }

    .tabs-content-tab, .tabs-content-tab-inner {
      display: none;
      visibility: hidden;
      margin-top: 24px;
    }

    .tabs-content-tab.active, .tabs-content-tab-inner.active {
      display: block;
      visibility: visible;
    }

    .tabs-content-tab .tab-fiat, .tabs-content-tab-inner .tab-fiat {
      display: flex;
      flex-direction: column;
    }

    .tabs-content-tab .tab-fiat__currency_info, .tabs-content-tab-inner .tab-fiat__currency_info {
      border: none;
      outline: none;
      cursor: pointer;
      transition: all ease-in-out .2s;
      background-color: transparent;
      border-radius: 10px;
      padding: 7px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .tabs-content-tab .tab-fiat__currency_info:hover, .tabs-content-tab-inner .tab-fiat__currency_info:hover {
      background: rgba(19, 25, 39, 0.5);
    }

    .tabs-content-tab .tab-fiat__currency_info__coin, .tabs-content-tab-inner .tab-fiat__currency_info__coin {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    .tabs-content-tab .tab-fiat__currency_info__coin p, .tabs-content-tab-inner .tab-fiat__currency_info__coin p {
      font-weight: 600;
      font-size: 16px;
      color: var(--popup-currency-white-light);
    }

    .tabs-content-tab .tab-fiat__currency_info__text p, .tabs-content-tab-inner .tab-fiat__currency_info__text p {
      text-align: right;
    }

    .tabs-content-tab .tab-fiat__currency_info__text p:first-child, .tabs-content-tab-inner .tab-fiat__currency_info__text p:first-child {
      font-weight: 600;
      font-size: 14px;
      color: var(--popup-currency-white-light);
    }

    .tabs-content-tab .tab-fiat__currency_info__text p:last-child, .tabs-content-tab-inner .tab-fiat__currency_info__text p:last-child {
      font-weight: 400;
      font-size: 13px;
      color: var(--popup-currency-purple);
    }

    .tabs-content-tab .tab-fiat__currency_info__text p:last-child span, .tabs-content-tab-inner .tab-fiat__currency_info__text p:last-child span {
      font-weight: 400;
      font-size: 13px;
      color: var(--popup-currency-white-light);
    }
  }
}

.popup-currency__context {
  position: relative;
  z-index: 1;
}

.popup-currency__context__header h5 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--popup-currency-white);
}

.popup-currency__context__content {
  margin-top: 27px;
}

/** Invite user */

.popup-invite-user {
  --popup-invite-user-white:#ffffff;
  --popup-invite-white-light:#F5F6F7;
  --popup-invite-black-blue:#1E273D;
  --popup-invite-purple:#657099;
  --popup-invite-black:#131927;
  width: 473px;
  height: auto;
}

.popup-invite-user__context {
  position: relative;
  z-index: 1;
}

.popup-invite-user__context__header h5 {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--popup-invite-user-white);
}

.popup-invite-user__context__content {
  margin-top: 24px;
}

.popup-invite-user__context__content__banner {
  width: 432px;
  height: 172px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.popup-invite-user__context__content__input {
  position: relative;
  width: 100%;
  margin-top: 24px;

  label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: var(--popup-invite-purple);
    margin-bottom: 6px;
  }

  input {
    background: var(--popup-invite-black);
    border-radius: 10px;
    padding: 11px 16px;
    padding-right: 37px;
    font-weight: 500;
    font-size: 12px;
    color: var(--popup-invite-white-light);
    border: none;
    outline: none;
    width: 100%;
    height: 48px;

    &:hover, &:focus, &:active {
      outline: none;
    }

    &::placeholder {
      color: var(--popup-invite-purple);
      opacity: 1;
    }

    &:-ms-input-placeholder, &::-ms-input-placeholder {
      color: var(--popup-invite-purple);
    }
  }

  button {
    border: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    color: var(--popup-invite-white-light);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    top: 37px;
    right: 16px;
  }
}

/** Reset password */
.popup-resetpassword {
  --popup-resetpass-h1: #FFFFFF;
  --popup-resetpass-form-bg: #1E273D;
  --popup-resetpass-form-border: rgba(49, 57, 81, 0.4);
  --popup-resetpass-input-bg: #131927;
  --popup-resetpass-input-color: #FFFFFF;
  --popup-resetpass-input-color-placeholder: rgba(101, 112, 153, 0.5);
  --popup-resetpass-label-color: #657099;
  --popup-resetpass-alert-color: #D32323;
  --popup-resetpass-closebtn-width: 20px;
  --popup-resetpass-closebtn-height: 20px;
  border-radius: 18px;
  padding: 16px;
  overflow: hidden;

  .f-button.is-close-btn {
    top: 20px;
    right: 13px;
    width: var(--popup-resetpass-closebtn-width);
    height: var(--popup-resetpass-closebtn-height);

    .f-button svg {
      width: var(--popup-resetpass-closebtn-width);
      height: var(--popup-resetpass-closebtn-height);
    }
  }
}

.popup-resetpassword__context {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 344px;
}

.popup-resetpassword__context__content {
  width: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 582px;
    height: 135px;
    left: -61px;
    top: -54px;
    background: #3B58E7;
    opacity: 0.4;
    filter: blur(100px);
  }
}

.popup-resetpassword__context__content__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--popup-resetpass-h1);
  text-align: left;
  text-transform: uppercase;
  margin-top: 3px;
  margin-bottom: 13px;
  padding-right: 25px;
}

.popup-resetpassword__context__content__form {
  background: var(--popup-resetpass-form-bg);
  border: 2px solid var(--popup-resetpass-form-border);
  border-radius: 10px;
  padding: 14px 14px 26px 13px;

  button {
    border-radius: 6px;
    margin-top: 16px;
    width: 100%;
  }
}

.popup-resetpassword__context__content__form__form_control {

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: var(--popup-resetpass-label-color);
    text-align: center;
    margin: 0 auto 12px;
  }

  input {
    width: 100%;
    padding: 15px 14px;
    background-color: var(--popup-resetpass-input-bg);
    font-weight: 400;
    font-size: 14px;
    color: var(--popup-resetpass-input-color);
    border-radius: 6px;
    border: 0;
    outline: none;

    &::placeholder {
      color: var(--popup-resetpass-input-color-placeholder);
    }
  }

  .popup-resetpassword__form__alert {
    display: none;
    color: var(--popup-resetpass-alert-color);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 13px;
  }
}

.resetpassword-form-error .popup-resetpassword__form__alert {
  display: block!important;
}

.popup-resetpassword__pin-code {
  display: flex;
  justify-content: center;

  input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    background: var(--popup-resetpass-input-bg);
    border-radius: 6px;
    margin: 4px 9px 2px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding: 0px 0px 2px 1px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.popup-resetpassword__form__pincode__email label {
  max-width: 80%;
}

.popup-resetpassword__context__content__form__form_control.form_control_password {
  position: relative;
  margin-bottom: 8px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 17px;
    right: 16px;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    margin: 0!important;
    border-radius: 0;
  }
}

.popup-resetpassword__context__content__form.popup-resetpassword__form__newpassword {

  label {
    margin-bottom: 16px;
  }

  button {
    margin-top: 18px;
  }
}

.resetpassword-form-hidden {
  display: none!important;
}

/** Task Hub */
.popup-taskhub {
  --popup-taskhub-bg: #0E121D;
  --popup-taskhub-color: #657099;
  --popup-taskhub-color-white: #FFFFFF;
  --popup-taskhub-color-blue: #3B58E7;
  --popup-taskhub-color-text: #5A6477;
  --popup-taskhub-toggler-bg: #131927;
  --popup-taskhub-progressbar: #1E273D;
  --popup-taskhub-item-bg: #1E273D;
  --popup-taskhub-item-border: rgba(49, 57, 81, 0.4);
  width: 473px;
  overflow: hidden;
  background-color: var(--popup-taskhub-bg);
  padding: 20px 0 10px 19px;

  .f-button.is-close-btn {
    top: 15px;
    right: 9px;
  }
  .popup-taskhub-color-white {
    color: var(--popup-taskhub-color-white);
  }
  .popup-taskhub-color-blue {
    color: var(--popup-taskhub-color-blue);
  }
  .popup-taskhub-context-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--popup-taskhub-color-white);
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 4px;
    padding-right: 40px;
  }
  .popup-taskhub-content-wrap {
    height: 632px;
    max-height: calc(80vh - 74px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 18px;

    &::-webkit-scrollbar {
      border-radius: 3px;
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #090614;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #313951;
    }

    .popup-taskhub-header {
      margin-bottom: 26px;

      .popup-taskhub-header-image {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 6px;
      }
      .popup-taskhub-progressbar {
        width: 100%;
        height: 8px;
        background-color: var(--popup-taskhub-progressbar);
        border-radius: 4px;
        margin-bottom: 6px;
      }
      .popup-taskhub-progressbar-fill {
        background-color: var(--popup-taskhub-color-blue);
        width: 0;
        height: 100%;
        border: 2px solid var(--popup-taskhub-progressbar);
        border-radius: 4px;
      }
      .popup-taskhub-progressbar-desc {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 14px;
      }
      .popup-taskhub-progressbar-rewards {
        display: flex;
        align-items: flex-start;

        img {
          margin: 1px 4px 0 9px;
        }

        .popup-taskhub-color-white {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .popup-taskhub-toggler {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: var(--popup-taskhub-toggler-bg);
      height: 32px;
      width: 100%;
      border-radius: 10px;
      margin-bottom: 14px;
      overflow: hidden;
      gap: 13px;

      .popup-taskhub-toggler-button {
        border: none;
        outline: none;
        height: 32px;
        width: auto;
        flex: 1;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--popup-taskhub-color);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        background-color: transparent;
        border-radius: 10px;
        transition: all ease-in-out 0.2s;

        &:hover {
          color: var(--popup-taskhub-color-white);
        }

        &.active {
          background-color: var(--popup-taskhub-color-blue);
          color: var(--popup-taskhub-color-white);
        }
      }
    }

    .popup-taskhub-content {

      .popup-taskhub-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: var(--popup-taskhub-item-bg);
        border: 2px solid var(--popup-taskhub-item-border);
        border-radius: 10px;
        margin-bottom: 8px;
        padding: 8px 8px 9px 8px;
        position: relative;
        text-decoration: none;

        .popup-taskhub-item-image {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .popup-taskhub-item-content {
          padding: 0px 48px 0px 16px;
          flex: 1 1 auto;

          h4 {
            color: var(--popup-taskhub-color-white);
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 3px;
          }

          p {
            color: var(--popup-taskhub-color-text);
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 9px;
          }

          .popup-taskhub-item-mark {
            position: absolute;
            right: 11px;
            bottom: 6px;
            background: var(--popup-taskhub-toggler-bg);
            border-radius: 24px;
            color: var(--popup-taskhub-color);
            height: 23px;
            display: flex;
            align-items: center;
            padding: 0px 12px 0 11px;
            font-size: 12px;
            font-weight: 500;

            img {
              margin: 1px 3px 0 0;
              width: 10px;
              height: auto;
            }
          }
        }
      }
    }

  }

  .popup-taskhub-item-hidden {
    display: none!important;
  }
}
.popup-taskhub:before {
  content: "";
  display: block;
  position: absolute;
  width: 582px;
  height: 135px;
  left: -61px;
  top: -54px;
  background: #3B58E7;
  opacity: 0.4;
  filter: blur(100px);
}


.popup-bonusfail {
  --popup-bonusfail-bg: #0E121D;
  --popup-bonusfail-color-title: #FFFFFF;
  --popup-bonusfail-color-text: #5A6477;
  width: 376px;
  overflow: hidden;
  background-color: var(--popup-bonusfail-bg);
  padding: 62px 40px 63px;
  border-radius: 18px;

  .f-button.is-close-btn {
    top: 16px;
    right: 6px;
  }

  .popup-bonusfail-context-title {
    color: var(--popup-bonusfail-color-title);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  .popup-bonusfail-context-text {
    color: var(--popup-bonusfail-color-text);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;

    span {
      color: var(--popup-bonusfail-color-title);
    }
  }

}
.popup-bonusfail:before {
  content: "";
  display: block;
  position: absolute;
  width: 582px;
  height: 135px;
  left: -61px;
  top: -54px;
  background: #3B58E7;
  opacity: 0.4;
  filter: blur(100px);
}

/** metamask email */

.popup-metamask-email {
  --popup-metamask-email-title-color:#ffffff;
  --popup-metamask-email-form-bg:#1E273D;
  --popup-metamask-email-form-border:rgba(49, 57, 81, 0.4);
  --popup-metamask-email-form-title:#3B58E7;
  --popup-metamask-email-form-input-bg:#131927;
  --popup-metamask-email-form-input-text:#F5F6F7;
  --popup-metamask-email-form-input-placeholder:rgba(101, 112, 153, 0.5);
  --popup-metamask-email-form-btn-bg:#3F4654;
  --popup-metamask-email-err:#D32323;
  --popup-metamask-email-success:#57BD26;
  width: 473px;
  height: auto;
}

.popup-metamask-email__context {
  position: relative;
  z-index: 1;
}

.popup-metamask-email__context__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: var(--popup-metamask-email-title-color);
  }
}

.popup-metamask-email__context__content {
  margin-top: 17px;
}

.popup-metamask-email__context__content__form {
  background: var(--popup-metamask-email-form-bg);
  border: 2px solid var(--popup-metamask-email-form-border);
  border-radius: 10px;
  padding: 15px 25px;

  form {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 14px;

    p {
      font-weight: 500;
      font-size: 15px;
      color: var(--popup-metamask-email-form-title);
    }

    input {
      width: 100%;
      background-color: var(--popup-metamask-email-form-input-bg);
      border-radius: 6px;
      padding: 9px 15px;
      font-weight: 600;
      font-size: 14px;
      color: var(--popup-metamask-email-form-input-text);
      border: 2px solid var(--popup-metamask-email-form-input-bg);
      outline: none;

      &::placeholder {
        color: var(--popup-metamask-email-form-input-placeholder);
        opacity: 1;
      }

      &:-ms-input-placeholder, &::-ms-input-placeholder {
        color: var(--popup-metamask-email-form-input-placeholder);
      }

      &:hover, &:focus {
        outline: none;
      }
    }

    button {
      &:disabled {
        background-color: var(--popup-metamask-email-form-btn-bg);
      }
    }

    &.error {
      p {
        color: var(--popup-metamask-email-err);
      }

      input {
        border-color: var(--popup-metamask-email-err);
        color: var(--popup-metamask-email-err);
      }
    }

    &.success p {
      color: var(--popup-metamask-email-success);
    }
  }
}
