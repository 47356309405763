.app-promotions .content {
  min-height: 80vh;
}

.promotions-tab-controls {
  --promotions-tab-controls-active: #3B58E7;
  --promotions-tab-controls-bg: #131927;
  --promotions-tab-controls-label-bg: #E73B3B;
  --promotions-tab-controls-label: #FFFFFF;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 37px;

  .promotions-label {
    font-size: 14px;
    font-weight: 700;
    color: var(--promotions-tab-controls-label);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .promotions-label-text {
      position: absolute;
      top: 0;
      right: 34px;
      bottom: 1px;
      left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tab-controls {
    background: var(--promotions-tab-controls-bg);
    border: 0;
    padding: 0;
    height: 34px;

    .tab-controls__buttons {
      height: 34px;

      button {
        box-shadow: none;
        border-radius: 10px;
        height: 34px;
        padding: 0 8px 0 12px;
        gap: 6px;
        min-width: 125px;
        justify-content: flex-start;
        margin: 0;

        .title {
          font-size: 14px;
        }

        .label {
          font-size: 11px;
          padding: 0 1px 0 0;
          margin-left: auto;
          border-radius: 8px;
          width: 20px;
          height: 16px;
          background-color: var(--promotions-tab-controls-label-bg);
          color: var(--promotions-tab-controls-label);
        }

        &.active {
          .label {
            background-color: var(--promotions-tab-controls-label);
            color: var(--promotions-tab-controls-active);
          }
        }
      }
    }
  }
}

.promotions-list {
  --promotions-list-item-bg: #13192F;
  --promotions-list-item-title: #FFFFFF;
  --promotions-list-item-text: #657099;
  --promotions-list-item-link: #3B58E7;

  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-right: -1px;
  margin-bottom: 14px;

  .promotions-item-image {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  .promotions-item {
    background: var(--promotions-list-item-bg);
    border-radius: 16px;
    overflow: hidden;
    width: 286px;
    max-width: calc(25% - 11px);

    .promotions-item-content {
      padding: 10px 18px 25px 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 106px;

      h4 {
        color: var(--promotions-list-item-title);
      }

      .promotions-item-link {
        display: flex;
        align-items: baseline;
        color: var(--promotions-list-item-link);
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;

        img {
          margin-left: 10px;
        }
      }
    }
  }

  .promotions-item-big {
    background: var(--promotions-list-item-bg);
    border-radius: 16px;
    overflow: hidden;
    width: 586px;
    max-width: calc(50% - 7px);

    .promotions-item-content {
      padding: 10px 26px 15px 18px;
      display: flex;
      justify-content: space-between;
      min-height: 106px;
      flex-wrap: wrap;
      gap: 15px;

      .promotions-item-description {
        max-width: 290px;

        h4 {
          color: var(--promotions-list-item-title);
          margin-bottom: 3px;
        }

        p {
          color: var(--promotions-list-item-text);
          font-size: 12px;
          font-weight: 500;
          line-height: 17px;
        }
      }

      .promotions-item-link {
        align-self: center;
        text-decoration: none;
        height: 46px;
        min-width: 141px;
      }
    }
  }
}
@media (max-width: 1600px) {
  .opened-sidebar.opened-chat .promotions-list .promotions-item {
    max-width: calc(33.333% - 10px);
  }
}
@media (max-width: 1366px) {
  .opened-sidebar.opened-chat .promotions-list .promotions-item-big {
    max-width: 100%;
  }
}
@media (max-width: 1200px) {
  .opened-chat .promotions-list .promotions-item-big {
    max-width: 100%;
  }
  .opened-sidebar.opened-chat .promotions-list .promotions-item {
    max-width: calc(50% - 7px);
  }
  .opened-chat .promotions-list .promotions-item {
    max-width: calc(33.333% - 10px);
  }
}
@media (max-width: 991px) {
  .opened-chat .promotions-list .promotions-item {
    max-width: calc(50% - 7px);
  }
}
@media (max-width: 767px) {
  .promotions-list .promotions-item-big {
    max-width: 100%;
    width: 100%;
  }
  .promotions-list .promotions-item {
    max-width: calc(50% - 7px);
    width: 100%;
  }
}
@media (max-width: 440px) {
  .promotions-list .promotions-item {
    max-width: 100%;
  }
  .promotions-list .promotions-item-big {
    width: 100%;
  }
}