.game-mode-bg {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
}

.app-gamemode {
  .content {
    padding-left: 7px;
    padding-right: 18px;
  }

  .pagination ul {
    padding-left: 8px;
  }
}

.game-mode-page {
  --game-mode-page-label-color:#35210E;
  --game-mode-page-freeze:#46D3FF;
  --game-mode-page-fire:#FF8946;
  --game-mode-page-cash:#6FC820;
  --game-mode-page-gamezone-bg:#0E121D;
  --game-mode-page-gamezone-border:rgba(48, 57, 86, 0.5);
  --game-mode-page-text-white:#ffffff;

  /** Override styles */
}

.game-mode-page__pagination {
  margin-top: 43px;
}

.game-mode-page__gamezone {
  background: var(--game-mode-page-gamezone-bg);
//   border: 2px solid var(--game-mode-page-gamezone-border);
//   border-radius: 16px;
//   padding: 12px 12px;
  position: relative;
  margin-top: 26px;
}

.game-mode-page__gamezone__gamewindow iframe {
    border: 0;
    position: relative;
}

.game-mode-page__gamezone__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: -2px;
  top: -52px;

  h1 {
    margin: 0;
    font-weight: 900;
    font-size: 26px;
    text-transform: uppercase;
    color: var(--game-mode-page-text-white);
  }
}

.game-mode-page__gamezone__controls__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;

  .form-switch {
    i {
      margin-right: 0px;
      width: 34px;
      flex: 34px 0 0;
      height: 18px;

      &::before, &::after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
      }
    }

    input:checked + i::after {
      transform: translate3d(16px, 0, 0);
    }
  }
}

.game-mode-page__gamezone__controls__actions__switcher .form-switch span {
  font-size: 14px;
  font-weight: 500;
  margin-right: 17px;
  margin-bottom: 1px;
}

.game-mode-page__gamezone__controls__actions__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.game-mode-page__gamezone__controls__actions__buttons__button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  height: 28px;
  width: 28px;

  &:hover, &:active {
    outline: none;
  }
}

.game-mode-page__gamezone__gamewindow {
  margin-top: 0;
  height: auto;
  position: relative;
  overflow: hidden;

  /** as example */
  iframe {
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  img {
    height: auto;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.game-mode-page__controls__tab_controls {
  margin-top: 32px;
}

.game-mode-page__controls__categories {
  margin-top: 16px;
}

.game-mode-page__catalog {
  margin-top: 72px;
}


.full-screen-game-mode {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0!important;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  overflow: hidden;
}

.game-mode-page__gamezone__balance-select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

#game-window-screen {
  display: none;
}
.full-screen-game-page {
  #notifications,
  chat,
  sidebar,
  .footer {
    display: none;
  }

  #game-theater-screen {
    display: none;
  }
  #game-window-screen {
    display: block;
  }
  .game-mode-page__gamezone__controls {
    justify-content: flex-end;
    position: static;
  }
  .game-mode-page__gamezone__gamewindow {
    height: calc(100% - 38px);
    margin-top: 10px;
  }
  .game-mode-page__gamezone__gamewindow {
    // height: 100%;

    iframe {
      height: 100%!important;
    }
  }
}

@media (max-width: 1025px) {
  .game-mode-page__gamezone {
    margin-top: 65px;
  }
}
@media (max-width: 991px) {
  .full-screen-game-mode-nocontrols {
    .game-mode-page__gamezone__controls {
      display: none;
    }
  }

  .full-screen-game-mode .game-mode-page__gamezone__balance-select {
    position: fixed;
  }


  .game-mode-page__gamezone__controls {
    left: 0;
  }
  .game-mode-page__gamezone__controls__actions {
    width: 100%;
    gap: 10px;
    justify-content: space-between;
  }
}

@media (min-width: 1366px) and (max-height: 767px) {
  .game-mode-page__pagination {
    width: 840px;
  }
}

.game-window-mobile {
  .game-mode-page__gamezone__gamewindow {
    margin: 0!important;
  }
}

.game-mode-page__gamezone.full-screen-game-mode .game-mode-page__gamezone__gamewindow {
  height: 100%!important;
}

.mobile-game-page {
  .main {
    padding: 0;
  }
  .content {
    padding: 0;
  }
  .full-screen-game-mode {
    padding: 0;
  }
}
