.banners-splide {
  height: 155px;
}
@media (max-width: 1200px) {
  .banners-splide {
    height: 110px;
  }
}
@media (max-width: 991px) {
  .banners-splide {
    height: 70px;
  }
}
@media (max-width: 440px) {
  .banners-splide {
    height: 75px;
  }
}
@media (max-width: 320px) {
  .banners-splide {
    height: 100px;
  }
}