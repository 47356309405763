@import "toastr";

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

:root {
  --homepage_noreg-text-color: #ffffff;
  --homepage_noreg-text-2-color: rgba(255, 255, 255, 0.5);
  --homepage_noreg-text-color-opacity: rgba(255, 255, 255, 0.8);
  --homepage_noreg-box-shadow-blue: #3B58E7;
  --homepage_noreg-box-shadow-black: rgba(0, 0, 0, 0.16);
  --homepage_noreg-toggle-btn-border-color: rgba(255, 255, 255, 0.1);
  --homepage_noreg-crypto-text-color: #525D82;
  --homepage_noreg-label-color: #35210E;
  --homepage_noreg-freeze: #46D3FF;
  --homepage_noreg-fire: #FF8946;
  --homepage_noreg-cash: #6FC820;
}

// Наши правки из до-адаптивной версии
.text-capitalize {
  text-transform: capitalize;
}

.error {
  color: #D32323;
  font-family: 'Inter';
  font-size: 0.8em;
  font-weight: 500;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex;
}

.content-between {
  justify-content: space-between;
}

.button_load_more {
  justify-content: center;
  display: flex;
  margin: 10px 0 10px 0;
  width: 100%;
}

.grecaptcha-badge {
  z-index: -1;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.dropdown-popup.filter input {
  background-color: #1e2639;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 13px;
  font-weight: 500;
  height: 36px;
  outline: none;
  padding: 0 12px;
  margin: 0 auto;
  width: 94%;
}

.dropdown-popup.filter input::placeholder {
  color: var(--dropdown-link-color);
}

.mt15 {
  margin-top: 15px;
}

.mt35 {
  margin-top: 175px;
}

.content {
  min-height: 65vh;
}

.game-mode-page__gamezone .game-mode-page__gamezone__gamewindow {
  height: calc(100vh - 260px) !important;
}

.game-mode-page__gamezone.full-screen-game-mode {
  .game-mode-page__gamezone__gamewindow {
    height: calc(100vh - 38px)!important;
  }
}

// Корректировка для дропдауна провайдеров под размер 248х350
.tab-controls {
  .dropdown-popup-toggler {
    min-width: 248px !important;
  }
}

.category-page__catalog {
  margin-top: 50px;

  .catalog {
    margin-top: 25px
  }

  .category-page__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    h2 {
      font-weight: 900;
      font-size: 24px;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

.page-redirect {


  color: #fff;
  text-align: center;

  h1 {
    color: #585d81;
    font-size: 175px;
    font-weight: 900;
    line-height: 339px;
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 39px;
    margin-bottom: 7px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

// .categories-controls-search{
//   width: 250px !important;
// }


.ios-fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}

.categories-controls-wrap {
  padding-right: 3px;
}

.full-screen-mobile {
    position:absolute;
    width:100%;
    height: 100%;

    iframe {
        border: 0;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;

    }
}

@media (max-width: 580px) {
  .splide-products .slider-component__title {
    flex-direction: row !important;
    align-items: flex-start;
    gap: 10px;
  }
}

@media (max-width: 991px) {
    .full-screen-game-page .header,
    .full-screen-game-page #tapbar,
    .full-screen-game-page+#tapbar,
    .full-screen-game-page+*+#tapbar {
        display: none!important;
    }
}
