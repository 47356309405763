.search-open {
  display: flex;
  align-items: center;
}

#search-curtain {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  z-index: 10;
}

#search {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 69px;
  z-index: 100;
  padding-left: 70px;
  --search-bg:#0E121D;
  --search-input-text-color:#657099;
}

.opened-search {
  #search, #search-curtain {
    display: block;
  }
}

.opened-sidebar #search {
  padding-left: 238px;
}

.opened-notifications #search, .opened-chat #search {
  padding-right: 382px;
}

#search {
  .search-container {
    color: #000;
    background: var(--search-bg);
    width: 1240px;
    padding-top: 22px;
    padding-right: 40px;
    padding-bottom: 24px;
    padding-left: 40px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 18px;
    position: relative;
    max-width: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 582px;
      height: 135px;
      right: 2%;
      top: -15%;
      background: #3B58E7;
      opacity: 0.4;
      filter: blur(100px);
      pointer-events: none;
    }
  }

  .search-header {
    margin-bottom: 25px;

    p {
      color: var(--search-input-text-color);
      font-size: 13px;
      line-height: 28px;
      font-weight: 500;
      text-align: center;
    }
  }

  .slider-component {
    display: none;
    margin: 0;

    &.search-show {
      display: block;
    }

    .splide {
      margin-top: 28px;
    }
  }

}

@media (max-width: 1366px) {
  .opened-sidebar #search {
    padding-left: 70px;
  }
}
@media (max-width: 1200px) {
  #search {
    top: 110px;
  }
}
@media (max-width: 991px) {
  #search {
    padding-left: 0!important;
  }
  .opened-notifications #search,
  .opened-chat #search {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  #search {
    top: 160px;
  }
}
@media (max-width: 580px) {
  #search {
    top: 210px;
  }
}