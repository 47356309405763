.favorites-page {
  .page-title {
    margin-bottom: 23px;
  }

  .splide-products {
    margin-top: 65px;
  }

  .slider-component {
    .splide {
      margin-top: 22px;
    }
  }
}

.app-favorites .footer {
  margin-top: 115px;
}