.content {
  width: 1185px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  margin: 0 auto;
  z-index: 2;
  max-width: 100%;
}

@media (max-width: 1790px) {
  .content {
    padding: 0 25px;
  }
}
@media (max-width: 1366px) {
  .content {
    padding: 0 13px;
  }
}