.pagination {
  --pagination-text-color:#5F6892;
  --pagination-text-color-active:#ffffff;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;

    li {
      &:not(.active)::after {
        content: ">";
        font-weight: 600;
        font-size: 12px;
        color: var(--pagination-text-color);
        padding-left: 10px;
      }

      a {
        font-weight: 600;
        font-size: 12px;
        color: var(--pagination-text-color);
        text-decoration: none;

        &:active, &:visited, &:focus, &:hover {
          color: var(--pagination-text-color);
        }
      }

      &.active a {
        color: var(--pagination-text-color-active);
      }
    }
  }
}
