.catalog {
  --catalog-box-shadow-blue: #3b58e7;
  --catalog-box-shadow-black: rgba(0,0,0,0.16);
}

.catalog-list {
  --catalog-label-color:#35210E;
  --catalog-freeze:#46D3FF;
  --catalog-fire:#FF8946;
  --catalog-cash:#6FC820;
  --catalog-hover-text-color:#FFFFFF;
  --catalog-hover-primary-color:#3B58E7;
  --catalog-hover-active-color: #4C6AFF;

  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  gap: 14px 14px;

  &>a,
  .catalog__list__item {
    border-radius: 14px;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    display: block;
    -webkit-perspective: 1000;
    -webkit-transform: translateZ(0);

    .catalog__list__item-image {
      overflow: hidden;
      padding-top: 133.34%;
      position: relative;

      .catalog__list__item-image-inner {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform .1s ease-in-out;

        img {
          display: block;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
        }
        /*
        img {
          height: auto;
          max-width: 100%;
          vertical-align: middle;
        }

        .img-wide {
          display: block;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
        }
        */
      }
    }

    &.cash {
      border: 2px solid var(--catalog-cash);
    }

    &.fire {
      border: 2px solid var(--catalog-fire);
    }

    &.freeze {
      border: 2px solid var(--catalog-freeze);
    }

    .label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      padding: 3px 11px 5px 8px;
      border-radius: 0 0 16px 0;
      min-width: unset;

      span {
        font-weight: 700;
        font-size: 12px;
        color: var(--catalog-label-color);
      }

      &.cash {
        background-color: var(--catalog-cash);
      }

      &.fire {
        background-color: var(--catalog-fire);
      }

      &.freeze {
        background-color: var(--catalog-freeze);
      }
    }

    .item-hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity .3s;
      opacity: 0;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
      z-index: 2;

      .item-hover-title {
        position: absolute;
        top: 0;
        color: var(--catalog-hover-text-color);
        text-align: center;
        padding: 16px 30px;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
        pointer-events: none;
      }

      .item-hover-provider {
        position: absolute;
        top: 80%;
        color: var(--catalog-hover-text-color);
        text-align: center;
        // padding: 150px 30px;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
      }

      .item-hover-favorite {
        position: absolute;
        top: 16px;
        right: 11px;
        display: flex;

        .item-hover-favorite-active {
          display: none;
        }

        &.active,
        &:hover {
          img {
            display: none;

            &.item-hover-favorite-active {
              display: inline;
            }
          }
        }
      }

      .item-hover-play {
        background: var(--catalog-hover-primary-color);
        border-radius: 50%;
        width: 49px;
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2px;

        &:hover {
          background: var(--catalog-hover-active-color);
        }
      }
    }

    &:hover {
      .item-hover {
        opacity: 1;
      }
    }
  }

  .empty-message {
    display: none;
  }
}

.catalog-list-empty {
  display: flex;

  a {
    display: none!important;
  }

  .empty-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 45vh;
    width: 100%;
    padding-right: 12px;

    h3 {
      text-align: center;
      color: #657099;
      font-size: 13px;
      font-weight: 600;
      line-height: 19px;
      margin: 18px 0 40px;
    }
  }
}

.catalog-button {
  margin-top: 68px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .button__blue {
    border-radius: 8px;
    height: 46px;
    width: 128px;
    box-shadow: 0px 0px 100px var(--catalog-box-shadow-blue), 0px 4px 40px var(--catalog-box-shadow-black);
  }
}
