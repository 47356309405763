.dropdown-popup-component {
  --dropdown-bg:#131927;
  --dropdown-border-color:rgba(49, 57, 81, 0.7);
  --dropdown-title-color:#E3EDF9;
  --dropdown-link-color:#657099;
  --dropdown-grey:#7B8390;
  --dropdown-white-light:#F5F6F7;
  --popup-currency-white:#ffffff;
  --popup-currency-white-light:#F5F6F7;
  --popup-currency-black-blue:#1E273D;
  --popup-currency-purple:#657099;
  --popup-currency-blue:#3B58E7;
  --popup-currency-black:#131927;
  position: relative;
  display: inline-block;
  background: var(--dropdown-bg);
  border: 2px solid var(--dropdown-border-color);
  border-radius: 8px;
  width: 100%;
}

.dropdown-popup-toggler {
  height: 32px;
  width: 100%;
  background-color: var(--dropdown-bg);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 8px;
  transition: background-color ease-in-out 0.3s;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 600;
      font-size: 13px;
      color: #7B8390;
    }
  }

  .subinfo p {
    font-weight: 400;
    font-size: 12px;

    &:first-child {
      color: var(--dropdown-grey);
    }

    &:last-child {
      color: var(--dropdown-white-light);
    }

    &.right {
      text-align: right;
    }

    &.left {
      text-align: left;
    }
  }

  .dropdown-arrow.dropdown-arrow-up {
    transform: rotate(180deg);
  }
}

.dropdown-popup {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: -99999;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  top: 120%;
  left: 0;
  border: 2px solid var(--dropdown-border-color);
  border-radius: 8px;
  background-color: var(--dropdown-bg);
  min-width: 100%;
  padding: 10px 0;

  &.filter {
    overflow: hidden;
    overflow-y: auto;
    max-height: 296px;

    &::-webkit-scrollbar-thumb {
      background-color: #2a3546;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #0d131ccc;
      border-radius: 2px;
    }
    &::-webkit-scrollbar {
      background-color: #0d131ccc;
      border-radius: 2px;
      height: 4px;
      width: 4px;
    }
  }
}

.dropdown-popup-active {
  opacity: 1;
  visibility: visible;
  display: block;
  z-index: 99999;
}

.dropdown-popup__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 7px 10px;
  background-color: transparent;
  border: none;
  text-decoration: none;

  span {
    color: var(--dropdown-link-color);
    font-weight: 500;
    font-size: 13px;
  }
}
.dropdown-popup__select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 7px 10px;
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;

  span {
    color: var(--dropdown-link-color);
    font-weight: 500;
    font-size: 13px;
  }
}

.dropdown-popup__link--active, .dropdown-popup__link:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.language {
  padding: 0;
  right: 0;
  top: 54px;
  left: auto;
  border-radius: 0;
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.language__content {
  position: relative;
  overflow: hidden;
  height: 80px;
  width: fit-content;
  padding: 15px;
  padding-top: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.language__content__context {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    span {
      font-weight: 600;
      font-size: 13px;
      color: white;
      text-transform: uppercase;
    }
  }
}

.emoji {
  right: -2px;
  left: -2px;
  top: unset;
  bottom: calc(100% + 5px);
}

.emoji__context {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
  height: 45vh;
  position: relative;
  overflow-y: auto;
  padding: 10px 14px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #090614;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3B58E7;
    border-radius: 8px;
  }

  button {
    background: none;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
  }
}

.user-account {
  padding: 0;
  right: -19px;
  top: 54px;
  left: auto;
  border-radius: 0;
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  max-width: calc(100vw - 60px);

  .user-account-content {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 412px;
    padding: 15px;
    max-width: 100%;
    padding-top: 24px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .context {
      position: relative;
      z-index: 100;

      .user-account-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: transparent;
        padding: 0;
        height: auto;

        .user {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          .info {
            h6 {
              font-weight: 700;
              font-size: 20px;
              color: var(--dropdown-white-light);
            }

            .awards {
              margin-top: 4px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
            }
          }
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          width: fit-content;
          height: auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          span {
            font-weight: 600;
            font-size: 13px;
            color: var(--dropdown-link-color);
          }
        }
      }

      .info2 {
        margin-top: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 600;
          font-size: 13px;
          color: var(--dropdown-white-light);
        }

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 17px;

          p:first-child {
            color: var(--dropdown-link-color);
          }
        }
      }

      .range {
        margin-top: 8px;
        background: #1E273D;
        border: 2px solid rgba(49, 57, 81, 0.4);
        border-radius: 10px;
        padding: 18px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        input {
          width: 85%;
          -webkit-appearance: none;
          background-color: #131927;
          background-image: linear-gradient(#3B58E7, #3B58E7);
          background-size: 20% 100%;
          background-repeat: no-repeat;
          border-radius: 20px;
          height: 9px;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
          }
        }

        label {
          font-weight: 600;
          font-size: 13px;
          color: var(--dropdown-link-color);
        }
      }

      .nav {
        margin-top: 8px;
        background: #1E273D;
        border: 2px solid rgba(49, 57, 81, 0.4);
        border-radius: 10px;
        padding: 18px 16px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: wrap;

        ul {
          flex: 1;
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 14px;

          li a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            text-decoration: none;

            span {
              font-weight: 600;
              font-size: 16px;
              color: var(--dropdown-link-color);
            }
          }
        }
      }

      .logout {
        margin-top: 18px;
        margin-left: 17px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        text-decoration: none;

        span {
          font-weight: 600;
          font-size: 16px;
          color: var(--dropdown-link-color);
        }
      }
    }
  }
}

.user-wallet {
  padding: 0;
  right: -92px;
  top: 42px;
  left: auto;
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;

  .user-wallet-content {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 273px;
    padding: 0;
    padding-top: 24px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .context {
      position: relative;
      z-index: 100;

      .finance {
        padding: 15px;

        .tabs-content {
          .tabs-toggle {
            background: var(--popup-currency-black);
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .tabs-toggle-button, .tabs-toggle-inner-button {
              flex: 1;
              padding: 12px 0;
              height: 32px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 12px;
              border-radius: 6px;
              transition: color ease-in-out .2s;
              border: none;
              outline: none;
              background-color: transparent;
              cursor: pointer;
            }

            .tabs-toggle-button img, .tabs-toggle-inner-button img {
              margin: 0 auto;
              transition: color ease-in-out .2s;
            }

            .tabs-toggle-button span, .tabs-toggle-inner-button span {
              font-weight: 500;
              font-size: 14px;
              color: var(--popup-currency-purple);
              text-align: center;
              transition: color ease-in-out .2s;
            }

            .tabs-toggle-button {
              &.active, &:hover {
                background-color: var(--popup-currency-blue);
              }
            }

            .tabs-toggle-inner-button {
              &.active, &:hover {
                background-color: var(--popup-currency-blue);
              }
            }

            .tabs-toggle-button {
              &.active *, &:hover * {
                color: var(--popup-currency-white);
              }
            }

            .tabs-toggle-inner-button {
              &.active *, &:hover * {
                color: var(--popup-currency-white);
              }
            }

            .tabs-toggle-button {
              &.active img, &:hover img {
                filter: brightness(0) invert(1);
              }
            }

            .tabs-toggle-inner-button {
              &.active img, &:hover img {
                filter: brightness(0) invert(1);
              }
            }
          }

          .tabs-content-tab, .tabs-content-tab-inner {
            display: none;
            visibility: hidden;
            margin-top: 24px;
          }

          .tabs-content-tab.active, .tabs-content-tab-inner.active {
            display: block;
            visibility: visible;
          }

          .tab-fiat {
            display: flex;
            flex-direction: column;
            height: 270px;
            position: relative;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px;
              border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px #090614;
              border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #3B58E7;
              border-radius: 8px;
            }
          }

          .tab-fiat__currency_info {
            border: none;
            outline: none;
            cursor: pointer;
            transition: all ease-in-out .2s;
            background-color: transparent;
            border-radius: 10px;
            padding: 7px 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:hover {
              background: rgba(19, 25, 39, 0.5);
            }
          }

          .tab-fiat__currency_info__coin {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            p {
              font-weight: 600;
              font-size: 16px;
              color: var(--popup-currency-white-light);
            }
          }

          .tab-fiat__currency_info__text p {
            text-align: right;

            &:first-child {
              font-weight: 600;
              font-size: 14px;
              color: var(--popup-currency-white-light);
            }

            &:last-child {
              font-weight: 400;
              font-size: 13px;
              color: var(--popup-currency-purple);

              span {
                font-weight: 400;
                font-size: 13px;
                color: var(--popup-currency-white-light);
              }
            }
          }
        }

        .classic-search {
          margin-top: 8px;
          position: relative;

          input {
            width: 100%;
            height: 32px;
            background: #131927;
            border-radius: 6px;
            font-weight: 500;
            font-size: 13px;
            color: #ffffff;
            border: none;
            outline: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            padding-right: 33px;

            &::placeholder {
              color: #657099;
              opacity: 1;
            }

            &:-ms-input-placeholder, &::-ms-input-placeholder {
              color: #657099;
            }

            &:hover, &:focus {
              outline: none;
            }
          }

          img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 15px;
          }
        }
      }

      .controls {
        background: #131927;
        position: relative;
        z-index: 5;
        margin-top: 6px;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        gap: 9px;
        border-top: 2px solid rgba(49, 57, 81, 0.4);

        label {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}
