.header {
  --header-action-btn-bg:#1A223D;
  --header-counter-bg:#E73B3B;
  --header-counter-text-color:#ffffff;
  --header-arrow-btn-bg:#1A223D;
  --header-search-bg:#131927;
  --header-search-input-text-color:#657099;
  --header-toggler-button-hover:#1E273D;
  --header-wallet-bg:#1E2639;
  --header-currency-bg:#6CDE07;
  --header-currency-text-color:#070814;
  background: #0e121d;
  width: 100%;
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  position: fixed;
  top: 0;
  z-index: 100;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(90deg, rgba(59, 88, 231, 0.2) 0%, #0e121d 20%, #0e121d 80%, rgba(59, 88, 231, 0.2) 100%);
  }
}

.header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .mobile-logo {
    display: none;
  }
}

.header__left__arrow__button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,&:active {
    outline: none;
  }
}

.header__left__arrow img {
  transform: rotate(180deg);

  &.header-nav-arrow-closed {
    transform: rotate(0deg);
  }
}

.header__center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex: 1;
  padding-left: 91px;
}

.header__center__search {
  background-color: var(--header-search-bg);
  height: 40px;
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 21px;
  border-radius: 6px;
}

.header__center__search__input {
  flex: 1;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--header-search-input-text-color);
  font-weight: 600;
  font-size: 13px;

  &::placeholder {
    color: var(--header-search-input-text-color);
    opacity: 1;
  }

  &:-ms-input-placeholder, &::-ms-input-placeholder {
    color: var(--header-search-input-text-color);
  }

  &:hover, &:focus {
    outline: none;
  }
}

.header__center__toggler {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--header-search-bg);
  height: 40px;
  width: 218px;
  border-radius: 6px;
}

.header__center__toggler__button {
  border: none;
  outline: none;
  height: 40px;
  width: auto;
  flex: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--header-search-input-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: transparent;
  border-radius: 6px;
  transition: all ease-in-out 0.2s;
}

.header__center__toggler__buttonhover {
  outline: none;
}

.header__center__toggler__button {
  &:active {
    outline: none;
  }

  &[disabled] {
    background-color: transparent!important;
    color: var(--header-search-input-text-color)!important;
    cursor: default;
    position: relative;

    svg path {
      fill: var(--header-search-input-text-color)!important;
      color: var(--header-search-input-text-color)!important;
    }

    .button-tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      top: 110%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover .button-tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  svg path {
    fill: var(--header-search-input-text-color);
    color: var(--header-search-input-text-color);
  }

  &:hover, &.active {
    background-color: var(--header-toggler-button-hover);
    color: var(--header-counter-text-color);
  }

  &:hover svg path, &.active svg path {
    fill: var(--header-counter-text-color);
    color: var(--header-counter-text-color);
  }
}

.header__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.header__right__actions, .header__right__auth {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.header__right__account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  gap: 14px;
}

.header__right__account__wallet {
  background-color: var(--header-wallet-bg);
  padding-left: 16px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  max-width: 219px;

  .button-wallet {
    img {
      display: none;
    }
  }

  .dropdown-popup-component {
    background: transparent;
    border: none;
    display: block;
    border-radius: 0;

    .dropdown-popup-toggler {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      height: fit-content;
      width: fit-content;
      background-color: transparent;

      &:hover, &:active {
        outline: none;
      }

      span {
        font-weight: 600;
        font-size: 13px;

        &:nth-child(1) {
          color: var(--header-counter-text-color);
        }

        &:nth-child(2) {
          background-color: var(--header-currency-bg);
          height: 14px;
          width: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--header-currency-text-color);
          border-radius: 50%;
          margin-left: 6px;
        }
      }

      img {
        margin-left: 25px;
      }
    }
  }
}

.header__right__account__actions {
  background-color: var(--header-wallet-bg);
  border-radius: 32px;
  padding-right: 17px;

  .dropdown-popup-component {
    background: transparent;
    border: none;
    display: block;
    border-radius: 0;

    .dropdown-popup-toggler {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      height: fit-content;
      width: fit-content;
      background-color: transparent;

      &:hover, &:active {
        outline: none;
      }
    }
  }
}

.header__right__actions {
  gap: 14px;

  .dropdown-popup-component {
    background: transparent;
    border: none;
    display: block;
    border-radius: 0;
    width: auto;

    .dropdown-popup-toggler {
      position: relative;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--header-action-btn-bg);
      border: none;
      outline: none;
      border-radius: 50%;
      cursor: pointer;

      &:hover, &:active {
        outline: none;
      }
    }
  }
}

.header__right__actions__button {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header-action-btn-bg);
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;

  &:hover, &:active {
    outline: none;
  }
}

.header__right__actions__button__counter {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: var(--header-counter-bg);
  height: 16px;
  width: auto;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  color: var(--header-counter-text-color);
  border-radius: 8px;
}

@media (max-width: 1790px) {
  .header__center {
    padding-left: 65px;
  }
}
@media (max-width: 1366px) {
  .header__center__search {
    width: 180px;
  }
}
@media (max-width: 1200px) {
  .header {
    flex-wrap: wrap;
    height: 110px;
    padding: 10px 25px;

    .header__left {
      order: 1;
      width: 25%;
    }

    .header__right {
      order: 2;
      width: 75%;
      justify-content: flex-end;
    }

    .header__center {
      order: 3;
      margin-top: 10px;
      padding: 0;

      .header__center__search {
        flex: 1 1 auto;
      }
    }
  }
}
@media (max-width: 991px) {
  .header {
    height: 61px;

    .header__left {

      .header__left__arrow {
        display: none;
      }

    }

    .header__center {
      display: none;

      .header__center__toggler {
        display: none;
      }
    }

    .header__right {
      gap: 14px;

      .header__right__account__actions {
        padding-right: 0;

        .dropdown-popup-toggler .dropdown-arrow {
          display: none;
        }
      }

      .header__right__actions {

        #language,
        #chat-toggle {
          display: none;
        }
      }
    }

    .header__right__account__wallet {
      padding-left: 10px;
      gap: 10px;
      .dropdown-popup-toggler {
        span:last-child,
        img {
          display: none;
        }
      }

      .button-wallet {
        padding: 0;

        img {
          display: inline;
        }
        span {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .header {

    .header__left {
      width: 135px;
    }

    .header__right {
      width: calc(100% - 135px);
      justify-content: flex-end;
    }
  }
}
@media (max-width: 580px) {
  .header {
    padding: 10px 14px;

    .header__left {
      width: 92px;

      .mobile-logo {
        display: block;
      }

      .desktop-logo {
        display: none;
      }
    }
    .header__center {
      padding: 0;
      gap: 10px;
      flex-direction: column;

      .header__center__search {
        width: 100%;
      }

      .header__center__toggler {
        width: 100%;
      }
    }

    .header__right {
      width: calc(100% - 92px);
      gap: 10px;

     .header__right__account__wallet {
       height: 34px;

       .button-wallet {
         height: 34px;
       }
     }
      .header__right__actions__button {
        height: 34px;
        width: 34px;
      }
      .header__right__account {
        gap: 10px;

        .header__right__account__actions {
          height: 34px;
          width: 34px;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .header {

    .header__left {
      width: 92px;
    }

    .header__right {
      width: calc(100% - 92px);
      justify-content: flex-end;
    }
  }
}
@media (max-width: 376px) {
  .header {
    .header__right {
      .header__right__auth {
        button {
          width: auto;
          padding: 8px 10px;
        }
      }
    }
  }
}
