.terms {
    color: rgba(255, 255, 255, 0.7);
    margin-top: 30px;

    h1, h2, h3, h4 {
        color: white;
        margin-top: 7px;
    }

    h1 {
        margin-bottom: 30px;
    }

    h2 {
        margin-bottom: 20px;
        color: white;
    }

    h3 {
        margin-bottom: 10px;
    }

    h4 {
        margin-bottom: 10px;
    }

    p {
        padding: 7px;
    }

    ol, ul {
        padding: 7px;
        margin-left: 15px;
    }

    li {
        padding: 7px;
    }
}
