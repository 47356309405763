
.popup-invite-user__context {
  .popup-invite-user__context__content {
    margin-bottom: 14px;
  }
  .popup-invite-user__context__controls {
    gap: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: calc(50% - 7px);
    }
  }
}