.vip_club {
  --vip-club-title-text-color:#ffffff;
  --vip-club-info-item-border-color:rgba(101, 112, 153, 0.2);
  --vip-club-info-p-color:#657099;
  --vip-club-subinfo-text-color:rgba(101, 112, 153, 0.7);
  --vip-club-infoblock-bg:#0E121D;
  --vip-club-infoblock-border-color:rgba(48, 57, 86, 0.5);
  --vip-club-box-shadow-blue:#3B58E7;
  --vip-club-box-shadow-black:rgba(0, 0, 0, 0.16);
  --vip-club-red-color:#E73B3B;
  --vip-club-inner-counter-text-color:#06060E;
  --vip-club-green-color:#53AE94;
  --vip-club-levels-delimiter-border-color:#282F48;
  --vip-club-box-shadow-insert:rgba(255, 255, 255, 0.5);
  --vip-club-bronze-light-title:#FE9245;
  --vip-club-silver-light-title:#CEEAFE;
  --vip-club-gold-light-title:#FCCC24;
  --vip-club-bronze-dark-title:#FD581B;
  --vip-club-silver-dark-title:#6FB0EB;
  --vip-club-gold-dark-title:#FF8F37;
  --vip-club-bronze-light:#FE9849;
  --vip-club-silver-light:#C9E7FD;
  --vip-club-gold-light:#FCDC14;
  --vip-club-bronze-light-shadow:rgba(254, 152, 73, 0.15);
  --vip-club-gold-light-shadow:rgba(252, 220, 20, 0.15);
  --vip-club-bronze-dark:#FD571A;
  --vip-club-silver-dark:#6EAFEA;
  --vip-club-gold-dark:#FF8733;
  --vip-club-bronze-dark-shadow:rgba(253, 87, 26, 0.15);
  --vip-club-gold-dark-shadow:rgba(255, 135, 51, 0.15);
  --vip-club-default-shadow:rgba(101, 112, 153, 0.15);
  --vip-club-who-p-color:rgba(101, 112, 153, 0.7);
  margin-top: 82px;

  /** Override styles */

  h2.subtitle {
    font-weight: 900;
    font-size: 40px;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
    text-align: center;
  }

  p.subinfo {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--vip-club-subinfo-text-color);
    text-align: center;
    max-width: 927px;
    margin: 0 auto;
    margin-top: 21px;
  }
}

.vip_club__hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.vip_club__hero__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.vip_club__hero__title__context h1 {
  margin: 0;
  font-weight: 900;
  font-size: 39px;
  text-transform: uppercase;
  color: var(--vip-club-title-text-color);
}

.vip_club__hero__title__context__info {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vip_club__hero__title__context__info__item {
  background-color: #131927;
  border: 2px solid var(--vip-club-info-item-border-color);
  border-radius: 16px;
  padding: 14px;
  position: relative;
  overflow: hidden;

  img.bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.vip_club__hero__title__context__info__item__context {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 5;

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
  }

  p {
    margin-top: 4px;
    font-weight: 600;
    font-size: 13px;
    color: var(--vip-club-info-p-color);
  }
}

.vip_club__hero__img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 540px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.vip_club__about {
  margin-top: 25px;
}

.vip_club__about__infoblocks {
  margin-top: 110px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
}

.vip_club__about__infoblocks__infoblock {
  flex: 1;
  background: var(--vip-club-infoblock-bg);
  border: 2px solid var(--vip-club-infoblock-border-color);
  border-radius: 16px;
  height: 217px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 15px;

  img {
    &.bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.img {
      position: absolute;
      top: -70px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
    }

    &.light {
      position: absolute;
      top: -190px;
      left: -70px;
      right: 0;
      margin: 0 auto;
    }

    &.separator {
      position: absolute;
      top: 89px;
      right: -27px;
      z-index: 1;
    }
  }
}

.vip_club__about__infoblocks__infoblock__text {
  margin-bottom: 34px;

  &.offers {
    margin-bottom: 52px;
  }

  h3 {
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
  }

  p {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: var(--vip-club-info-p-color);
    margin-top: 12px;
  }
}

.vip_club__about__infoblocks__infoblock:last-child img.img {
  top: -50px;
}

.vip_club__work {
  margin-top: 97px;
}

.vip_club .vip_club__work p.subinfo {
  margin-top: 9px;
}

.vip_club__work__infoblocks {
  margin-top: 40px;
  min-height: 245px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 14px;
}

.vip_club__work__infoblocks__infoblock {
  position: relative;
  max-width: 300px;
}

.vip_club__work__infoblocks__infoblock__img {
  height: 160px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {}
}

.vip_club__work__infoblocks__infoblock__text {
  text-align: center;
  position: relative;
  z-index: 1;

  &.offers {
    margin-bottom: 58px;
  }

  h3 {
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
  }

  p {
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    color: var(--vip-club-info-p-color);
    margin: 9px auto 0;
    max-width: 270px;
  }
}

.vip_club__work__infoblocks__infoblock {
  &:nth-child(1) .infoblock__img img {
    margin: 0px 0px 15px 11px;
  }

  &:nth-child(2) {
    .vip_club__work__infoblocks__infoblock__text p {
      max-width: 250px;
    }

    .infoblock__img img {
      margin: 0px 12px 9px 0px;
    }
  }

  &:nth-child(3) .infoblock__img img {
    margin: 4px 97px 0 0;
  }
}

.vip_club__work__button {
  margin-top: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    border-radius: 8px;
    height: 46px;
    width: 119px;
    box-shadow: 0px 0px 100px var(--vip-club-box-shadow-blue), 0px 4px 40px var(--vip-club-box-shadow-black);
  }
}

.vip_club__levels {
  margin-top: 94px;

  .blue-shadow {
    position: absolute;
    top: 0;
    left: 0;

    &:last-child {
      right: 0;
    }
  }
}

.vip_club__levels__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vip_club__levels__title__text {
  flex: 1;

  h2 {
    text-align: center;
    flex: 1;

    span {
      font-weight: 900;
      font-size: 40px;
      text-transform: uppercase;
      color: var(--vip-club-title-text-color);

      &:last-child {
        color: var(--vip-club-red-color);
      }
    }
  }
}

.vip_club .vip_club__levels p.subinfo {
  margin-top: 9px;
  max-width: 800px;
}

.vip_club__levels__title__slider_controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  opacity: 0;
  visibility: hidden;
  position: relative;
  z-index: -1;

  .button {
    height: 32px;
    width: 32px;

    &:disabled {
      opacity: 0.4;
    }

    &:first-child img {
      transform: rotate(-180deg);
    }
  }
}

.vip_club__levels img {
  &.img {
    position: absolute;
    left: 0;
    right: 0;
    top: -27px;
    margin: 0 auto;
    z-index: 1;
  }

  &.silver {
    top: -47px;
  }

  &.gold {
    top: -70px;
  }
}

.vip_club__who {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  min-height: 740px;
  margin-bottom: 27px;
}

.vip_club__who__img {
  height: 740px;
  width: 668px;

  img {
    position: absolute;
    top: -100px;
    left: -294px;
  }
}

.vip_club__who__title {
  align-self: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 5;
  flex: 1;
}

.vip_club__who__title__context {
  h5 {
    font-weight: 900;
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 7px;
    color: var(--vip-club-title-text-color);
  }

  p {
    margin-bottom: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--vip-club-who-p-color);
  }

  .button {
    margin-top: 22px;
    width: 173px;
    height: 46px;
  }
}

.vip_club__faq {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h5 {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
  }
}

.vip_club__faq__toggler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0e121d;
  border: 2px solid rgba(48, 57, 86, 0.5);
  border-radius: 16px;
  padding: 24px;
  cursor: pointer;
  position: relative;

  img.faq-bg {
    display: block;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.vip_club__faq__toggler__text {
  flex: 1;

  h6 {
    font-weight: 600;
    font-size: 15px;
    color: var(--vip-club-title-text-color);
    text-align: left;
  }

  p {
    display: none;
    visibility: hidden;
    text-align: left;
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    color: var(--vip-club-info-p-color);
  }
}

.vip_club__faq__toggler.show {
  p {
    display: block;
    visibility: visible;
  }

  img.faq-arrow {
    transform: rotate(180deg);
  }
}

.vip_club {
  .splide__arrow {
    top: -42px;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    background-color: #3D58E5;
    color: #ffffff;

    * {
      color: #ffffff;
      fill: #ffffff;
      stroke: #ffffff;
    }

    svg {
      height: 10px;
      width: auto;
    }
  }

  .splide__arrow--prev {
    left: auto;
    right: 40px;
  }

  .splide__arrow--next {
    right: 0;
  }
}

.shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: fit-content;
  height: auto;
  min-height: 26px;
  min-width: 26px;
  background-color: var(--vip-club-default-shadow);

  &.shadow-bronze {
    background: linear-gradient(180deg, var(--vip-club-bronze-light-shadow) 0%, var(--vip-club-bronze-dark-shadow) 100%);
  }

  &.shadow-gold {
    background: linear-gradient(180deg, var(--vip-club-gold-light-shadow) 0%, var(--vip-club-gold-dark-shadow) 100%);
  }
}

.shadow__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 800;
  font-size: 13px;
  color: var(--vip-club-inner-counter-text-color);
  width: fit-content;
  height: auto;
  min-height: 18px;
  min-width: 18px;
  background-color: var(--vip-club-info-p-color);
  box-shadow: inset 0px 2px 4px var(--vip-club-box-shadow-insert);

  &.bg-bronze {
    background: linear-gradient(180deg, var(--vip-club-bronze-light) 0%, var(--vip-club-bronze-dark) 100%);
  }

  &.bg-silver {
    background: linear-gradient(180deg, var(--vip-club-silver-light) 0%, var(--vip-club-silver-dark) 100%);
  }

  &.bg-gold {
    background: linear-gradient(180deg, var(--vip-club-gold-light) 0%, var(--vip-club-gold-dark) 100%);
  }
}

/* slider context */

#vip-club-levels-list-slider {
  margin-top: 20px;

  .splide__track {
    padding-top: 60px;
    margin-top: -60px;
  }
}


.slide_context__slider_item {
  margin-top: 89px;
  position: relative;
  overflow: hidden;
  background: var(--vip-club-infoblock-bg);
  border: 2px solid var(--vip-club-infoblock-border-color);
  border-radius: 16px;
  min-height: 453px;
  width: auto;

  img.bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.slide_context__slider_item__info {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 30px;
}

.slide_context__slider_item__info__counter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .shadow {
    min-height: 32px;
    min-width: 32px;
  }

  .shadow__bg {
    min-height: 22px;
    min-width: 22px;
  }
}

.slide_context__slider_item__info__statistics {
  display: block;
  width: 100%;
  margin-top: 17px;
  padding-bottom: 26px;
  border-bottom: 2px solid var(--vip-club-levels-delimiter-border-color);

  h4 {
    font-weight: 900;
    font-size: 26px;
    text-transform: uppercase;
    color: var(--vip-club-title-text-color);
    text-align: center;
  }
}

.slide_context__slider_item__info__statistics__info {
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.slide_context__slider_item__info__statistics__info__item {
  flex: 33% 0 0;
}

.slide_context__slider_item__info__statistics__info__item-total {
  flex: 43% 0 0;
}

.slide_context__slider_item__info__statistics__info__item-rank {
  flex: 24% 0 0;
}

.slide_context__slider_item__info__statistics__info__item__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;

  h5 {
    font-weight: 700;
    font-size: 20px;

    &.default {
      color: var(--vip-club-green-color);
    }
  }

  &.bronze-title {
    background: linear-gradient(180deg, var(--vip-club-bronze-light-title) 26.79%, var(--vip-club-bronze-dark-title) 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &.silver-title {
    background: linear-gradient(180deg, var(--vip-club-silver-light-title) 26.79%, var(--vip-club-silver-dark-title) 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &.gold-title {
    background: linear-gradient(180deg, var(--vip-club-gold-light-title) 26.79%, var(--vip-club-gold-dark-title) 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.slide_context__slider_item__info__statistics__info__item p {
  font-weight: 600;
  font-size: 13px;
  color: var(--vip-club-info-p-color);
}

.slide_context__slider_item__info__checks {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.slide_context__slider_item__info__checks__items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.slide_context__slider_item__info__checks__items__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  p {
    font-weight: 600;
    font-size: 13px;
    color: var(--vip-club-info-p-color);

    &.active {
      color: var(--vip-club-title-text-color);
    }
  }
}

@media (max-width: 1790px) {
  .vip_club {
    .vip_club__hero__img {
      height: 370px;
    }

    .vip_club__who {
      height: 704px;
    }

    .vip_club__who__img {
      width: 425px;
    }
  }
}
@media (max-width: 1366px) {
  .vip_club {
    margin-top: 34px;

    .vip_club__hero__img {
      position: absolute;
      top: 80px;
      left: 0;
      width: 700px;
      max-width: 100%;
    }
  }
  .vip_club__hero__title {
    z-index: 2;
    width: 100%;
  }
  .vip_club__hero__title__context {
    width: 100%;
  }
  .vip_club__hero__title__context__info {
    margin-top: 350px;
  }

  .vip_club__about__infoblocks {
    flex-direction: column;
    align-items: center;
    margin-top: 89px;
  }
  .vip_club__about__infoblocks__infoblock {
    width: calc(100% - 50px);
    height: auto;
    padding-top: 59px;
    padding-bottom: 20px;
    margin-bottom: 62px;

    img.separator {
      display: none;
    }
  }
  .vip_club__about__infoblocks__infoblock__text h3 {
    font-size: 20px;
  }



  .vip_club {
    .vip_club__who {
      height: auto;
      flex-wrap: wrap;
      min-height: unset;
    }
    .vip_club__who__img {
      width: 100%;
      height: auto;

      img {
        position: static;
        max-width: 100%;
        max-height: 630px;
        height: auto;
      }
    }
  }
}
@media (max-width: 991px) {


  .vip_club__work {
    margin-top: 0;
  }
  .vip_club__work__infoblocks {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 26px;
    gap: 50px;
  }
  .vip_club .vip_club__work p.subinfo {
    margin-top: 6px;
  }

}
@media (max-width: 767px) {
  #vip-club-levels-list-slider {
    margin-top: 90px;
  }
  .vip_club__levels__title__slider_controls {
    display: none;
  }
  .vip_club .splide__arrow {
    top: 17px;
  }
}
@media (max-width: 580px) {
  .vip_club__hero__title__context__info {
    gap: 6px;
  }
  .vip_club__hero__title__context h1 {
    font-size: 32px;
  }
  .vip_club__hero__title__context__info__item__context__img {
    flex: 50px 0 0;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .vip_club__hero__title__context__info__item {
    padding: 13px 6px 14px 16px;
  }
  .vip_club__hero__title__context__info__item img.bg {
    width: 200%;

  }
  .vip_club__hero__title__context__info__item__context {
    align-items: flex-start;

    h3 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
      letter-spacing: -0.2px;
    }
  }

  .vip_club__about {
    margin-top: 43px;
  }
  .vip_club {
    h2.subtitle {
      font-size: 32px;
    }
    p.subinfo {
      font-size: 14px;
      line-height: 17px;
    }
  }
}