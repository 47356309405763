.page404-bgimage1 {
  position: absolute;
  left: 0px;
  top: 69px;
}
.page404-bgimage2 {
  position: absolute;
  right: 0;
  top: 0;
}
.page-404 {
  color: #fff;
  text-align: center;
  padding-top: 238px;
  padding-bottom: 200px;

  h1 {
    color: #191b2a;
    font-size: 280px;
    font-weight: 900;
    line-height: 339px;
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 39px;
    margin-bottom: 7px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

@media (max-width: 1366px) {
  .page-404 {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  .opened-chat .page-404,
  .opened-sidebar.opened-chat .page-404 {
    h1 {
      font-size: 150px;
      line-height: 180px;
    }
  }
}
@media (max-width: 991px) {
  .page-404 h1 {
      font-size: 150px;
      line-height: 180px;
  }
}
@media (max-width: 440px) {
  .page-404 h1 {
      font-size: 110px;
      line-height: 130px;
  }
}