.table {
  --table-th-text-color:#657099;
  --table-tr-bg:rgba(19, 25, 39, 0.5);
  --table-tr-border-color:rgba(49, 57, 81, 0.4);
  --table-tr-td-text-color:#ffffff;
  --table-profit-positive-text-color:#6FC820;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0 12px;

  thead tr th {
    padding: 4px 22px;
    text-align: left;

    span {
      font-weight: 600;
      font-size: 13px;
      color: var(--table-th-text-color);
    }
  }

  tbody tr td {
    background: var(--table-tr-bg);
    border-top: 2px solid var(--table-tr-border-color);
    border-bottom: 2px solid var(--table-tr-border-color);

    &:first-child {
      border-left: 2px solid var(--table-tr-border-color);
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-right: 2px solid var(--table-tr-border-color);
      border-radius: 0 8px 8px 0;
    }

    .container {
      width: 100%;
      height: auto;
      padding: 4px 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;

      span {
        font-weight: 700;
        font-size: 13px;
        color: var(--table-tr-td-text-color);

        &.profit.positive {
          color: var(--table-profit-positive-text-color);
        }
      }
    }

    .empty_data {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 244px;

      div {
        text-align: center;

        img {
          margin: 0 auto;
        }

        p {
          margin-top: 18px;
          font-weight: 600;
          font-size: 13px;
          color: var(--table-th-text-color);
        }
      }
    }
  }
}

.custom-table {
  --custom-table-th-text-color:#657099;
  --custom-table-tr-bg:rgba(19, 25, 39, 0.5);
  --custom-table-tr-border-color:rgba(49, 57, 81, 0.4);
  --custom-table-tr-td-text-color:#F5F6F7;
  --custom-table-successful:#57BD26;
  --custom-table-pending:#CFA81C;
  --custom-table-rejected:#D32323;
  width: 100%;
  height: auto;
}

.custom-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    color: var(--custom-table-th-text-color);
    padding: 6px 12px;
  }
}

.custom-table__body {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.custom-table__body__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--custom-table-tr-bg);
  border: 2px solid var(--custom-table-tr-border-color);
  border-radius: 8px;

  span {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    padding: 6px 12px;
    color: var(--custom-table-tr-td-text-color);

    &.successful {
      color: var(--custom-table-successful);
    }

    &.pending {
      color: var(--custom-table-pending);
    }

    &.rejected {
      color: var(--custom-table-rejected);
    }
  }
}
